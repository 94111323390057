import { closeBanner } from '~shared/actions/banners';
import getShowProgynySelfServiceForUser from '~shared/features/users/selectors/getShowProgynySelfServiceForUser';
import getAuth from '~shared/selectors/auth/getAuth';
import { connect } from '~shared/store';
import { DashboardNavbar } from './DashboardNavbar';
import { getIsHubReferralInfoRequestRoute } from '~web/features/onboarding/selectors/getIsHubReferralInfoRequestRoute';
import getIsOnboardingRoute, {
  getIsOrganicOnboardingLandingPage,
} from '~web/features/onboarding/selectors/getIsOnboardingRoute';

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state) => {
  const { banners } = state;
  const { showBanner } = banners;

  const { authorized, onboarding: oldOnboarding } = getAuth(state);
  const newOnboarding = getIsOnboardingRoute(state);
  const isOrganicOnboardingLandingPage = getIsOrganicOnboardingLandingPage(state);
  const showProgyny = getShowProgynySelfServiceForUser(state);
  const isHubReferralInfoRequestRoute = getIsHubReferralInfoRequestRoute(state);

  return {
    authorized,
    onboarding: oldOnboarding || newOnboarding || isOrganicOnboardingLandingPage,
    showBanner,
    showProgyny,
    isHubReferralInfoRequestRoute,
  };
};

// @ts-expect-error TS(7006): Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => {
  return {
    closeBanner: () => {
      dispatch(closeBanner());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
