import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import { Card, Description, H3, InlineAlert, LgPadding, LgSpacing } from '@alto/design-system';
import { DeliveryLogistics, useCheckoutValidationErrors } from '@alto/features';
import React, { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { isMail } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { EditCartButton } from './EditCartButton';
import { MedSummaryList } from './MedSummaryList';

export const DeliverySummary = () => {
  const orderIndex = useSelectorShared(getOrderIndex);
  const {
    date,
    deliver_after,
    deliver_before,
    home_to_sign_confirmation,
    include_sharps_container,
    is_asap: isAsapDelivery,
  } = useSelectorShared(getOrder);

  const { cartItemsByDeliveryMethod, deliveryMethods } = useCartDeliveryMethods();
  const shipmentCount = deliveryMethods.length;
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const deliveryMethodName = deliveryMethod?.name;
  const { hasValidationErrors } = useCheckoutValidationErrors();
  const { compounds } = usePrescriptionsInCart();
  const compoundIDs = new Set(compounds.map((c) => c.id));
  let cartItems = cartItemsByDeliveryMethod[deliveryMethodName] ?? [];

  const isSameDelivery =
    shipmentCount > 1 && deliveryMethods.every((method) => deliveryMethods[0]?.mode === method?.mode);
  // edge case, if the delivery method is the same filter out the compound meds from the second shipment
  if (orderIndex === 0 && isSameDelivery) {
    cartItems = cartItems.filter((item) => compoundIDs.has(item.resource_id));
  } else if (orderIndex > 0 && isSameDelivery) {
    cartItems = cartItems.filter((item) => !compoundIDs.has(item.resource_id));
  }

  const sharpsContainerMethod = useMemo(() => {
    let sharpsContainerMethod: DeliveryMethod | null;
    const nonMailMethods = deliveryMethods.filter((method) => !isMail(method.mode));

    if (nonMailMethods.length > 0) {
      [sharpsContainerMethod] = nonMailMethods;
    } else {
      [sharpsContainerMethod] = deliveryMethods;
    }
    return sharpsContainerMethod;
  }, [deliveryMethods]);

  return (
    <Card title="Order summary">
      {shipmentCount > 1 && (
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          bottomPadding={SPACING.STATIC.NONE}
        >
          <H3>{`Order ${orderIndex + 1} of ${shipmentCount}`}</H3>
        </LgPadding>
      )}
      {!hasValidationErrors ? (
        <DeliveryLogistics
          date={date}
          homeToSignConfirmation={home_to_sign_confirmation}
          method={deliveryMethod}
          status="upcoming"
          deliverAfter={deliver_after}
          deliverBefore={deliver_before}
          isAsapDelivery={isAsapDelivery}
        />
      ) : (
        <LgPadding>
          <InlineAlert type="warning">
            <Description>Please complete your order to view your order details.</Description>
          </InlineAlert>
        </LgPadding>
      )}
      <LgPadding
        topPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
      >
        <MedSummaryList
          cartItems={cartItems}
          includeSharpsContainer={include_sharps_container ? deliveryMethodName === sharpsContainerMethod?.name : false}
        />
        <EditCartButton />
        <LgSpacing />
      </LgPadding>
    </Card>
  );
};
