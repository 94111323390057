import { SIZES, SPACING } from '@alto/design-library-tokens';
import { useScreenSize } from '@alto/design-system';
// eslint-disable-next-line @alto/no-pocky-import
import { FlexColumn, FlexRow, media, palette, spacing } from '@alto/pocky';
import React, { type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSelectorShared } from '~shared/store';
import { getIsHubReferralInfoRequestRoute } from '~web/features/onboarding/selectors/getIsHubReferralInfoRequestRoute';
import getIsOnboardingRoute, {
  getIsOrganicOnboardingLandingPage,
} from '~web/features/onboarding/selectors/getIsOnboardingRoute';

export const FOOTER_HEIGHT = {
  PHONE: '261px',
  TABLET: '285px',
  DESKTOP: '141px',
};

const Wrapper = styled.div`
  min-height: 100vh;
`;

// min-height -181px to keep footer at the bottom
const AuthorizedPageContainer = styled.div<{
  isSMScreenOrBigger: boolean;
  isMDScreenOrBigger: boolean;
  isLGScreenOrBigger: boolean;
  withoutDashboardNavBar: boolean;
}>`
  min-height: calc(100vh - ${FOOTER_HEIGHT.PHONE});
  padding-top: ${({ withoutDashboardNavBar }) =>
    withoutDashboardNavBar ? SPACING.STATIC.NONE : SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM};
  margin: 0 auto;

  ${({ isMDScreenOrBigger, withoutDashboardNavBar }) =>
    isMDScreenOrBigger && !withoutDashboardNavBar && `padding-top: ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG};`}
`;

// min-height -180px to keep footer at the bottom
const UnauthorizedPageContainer = styled.div<{
  isSMScreenOrBigger: boolean;
  isMDScreenOrBigger: boolean;
  isLGScreenOrBigger: boolean;
  withoutDashboardNavBar: boolean;
}>`
  padding-top: ${({ withoutDashboardNavBar }) =>
    withoutDashboardNavBar ? SPACING.STATIC.NONE : SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM};
  min-height: calc(100vh - 180px);

  ${({ isSMScreenOrBigger }) =>
    isSMScreenOrBigger &&
    `
    min-height: calc(100vh - 161px);

`}

  ${({ isMDScreenOrBigger, withoutDashboardNavBar }) =>
    isMDScreenOrBigger && !withoutDashboardNavBar && `padding-top: ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG};`}
`;

const narrowSmTabletCss = css`
  min-width: unset;
  width: 100%;
  padding: 0;
`;

// eslint-disable-next-line @alto/no-media-queries
const NarrowFlexRow = styled(FlexRow)`
  min-width: 512px;
  width: 50%;
  margin: 0 auto;
  align-self: center;
  > * {
    width: 100%;
  }
  ${media.smTablet`${narrowSmTabletCss}`}
`;

// eslint-disable-next-line @alto/no-media-queries
const OnboardingFlexRow = styled(NarrowFlexRow)`
  ${media.smTablet`
    padding: 0 ${SPACING.STATIC.LG.px};
  `}
`;

const wrapperTabletCss = css`
  flex-direction: column;
  > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: ${spacing.mdSpacing};
  }
`;

// eslint-disable-next-line @alto/no-media-queries
const StyledFlexColumn = styled(FlexColumn)`
  ${media.tablet`${wrapperTabletCss}`}
`;

type ContentProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
/*
 * narrow single column layout
 * (if you want a single column layout that extends to the full width of the authorized/unauthorized page container you don't need SingleColumnContentWrapper)
 *
 * <SingleColumnContentWrapper>
 *   <div>foo</div>
 *   <div>bar</div>
 * </SingleColumnContentWrapper>
 *
 * +-------------+
 * |    +---+    |
 * |    |foo|    |
 * |    +---+    |
 * |    |bar|    |
 * |    +---+    |
 * +-------------+
 *
 */

export const SingleColumnContentWrapper = (props: ContentProps) => (
  <NarrowFlexRow
    className={props.className}
    spacing={spacing.mdSpacing}
    wrap
    horizontallyAlignContent
  >
    {props.children}
  </NarrowFlexRow>
);

export const OnboardingContentWrapper = (props: ContentProps) => (
  <OnboardingFlexRow
    className={props.className}
    spacing={spacing.mdSpacing}
    wrap
    horizontallyAlignContent
  >
    {props.children}
  </OnboardingFlexRow>
);

/*
 * basic content layout with sidebar
 * (if you want a single column layout that extends to the full width of the authorized/unauthorized page container you don't need ContentWrapper)
 *
 * <ContentWrapper>
 *    <Main>Stuff here</Main>
 *    <Sidebar>Sidebar Stuff here</Sidebar>
 * </ContentWrapper>
 *
 * +-------------+
 * | +------+--+ |
 * | | main |sb| |
 * | |      |  | |
 * | +------+--+ |
 * +-------------+
 *
 */
export const ContentWrapper = (props: { readonly children: ReactNode }) => (
  <StyledFlexColumn
    spacing={spacing.mdSpacing}
    wrap
  >
    {props.children}
  </StyledFlexColumn>
);

export const Main = styled.div<{ wide?: boolean; children?: ReactNode }>`
  width: 100%;

  flex: ${({ wide }) => {
    return wide ? '2' : '3';
  }};
  > *:not(:last-child) {
    margin-bottom: ${spacing.mdSpacing};
  }
`;

export const Sidebar = styled.div`
  flex: 1;
`;

/*
 * content with breadcrumbs
 * <ContentWrapper>
 *    <FullFlexRow>
 *      <Breadcrumbs />
 *      <H1>heading</H1>
 *    </FullFlexRow>
 *    <Main>Stuff here</Main>
 *    <Sidebar>Sidebar Stuff here</Sidebar>
 * </ContentWrapper>
 *
 * +-------------+
 * | +---------+ |
 * | | - >- >- | |
 * | +---------+ |
 * | | H1      | |
 * | +------+--+ |
 * | | main |sb| |
 * | |      |  | |
 * | +------+--+ |
 * +-------------+
 *
 */
export const FullFlexRow = styled(FlexRow)`
  flex-grow: 1;
  margin-bottom: ${spacing.mdSpacing};
  margin-right: 0;
  width: 100%;
  &:not(:last-child) {
    margin-right: 0;
  }
  > *:not(:last-child) {
    margin-bottom: ${spacing.mdSpacing};
  }
`;

/*
 * content with arbitrary columns
 * <ContentWrapper>
 *    <FullFlexColumn>
 *      <div>1</div>
 *      <div>2</div>
 *    </FullFlexColumn>
 *    <Main>Stuff here</Main>
 *    <Sidebar>Sidebar Stuff here</Sidebar>
 * </ContentWrapper>
 *
 * +-------------+
 * | +---------+ |
 * | | 1  | 2  | |
 * | +------+--+ |
 * | | main |sb| |
 * | |      |  | |
 * | +------+--+ |
 * +-------------+
 *
 */
export const FullFlexColumn = styled(FlexColumn)`
  flex-grow: 1;
  margin-bottom: ${spacing.mdSpacing};
  margin-right: 0;
  width: 100%;
  &:not(:last-child) {
    margin-right: 0;
  }
`;

// eslint-disable-next-line @alto/no-media-queries
export const PageBackground = styled.div<{
  white?: boolean;
  color?: string;
  fullWidth?: boolean;
  maxWidth?: string;
}>`
  background-color: ${({ white, color }) => {
    if (white) return palette.white;
    if (color) return color;
    return palette.warmGrey;
  }};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '66.67%')};
  max-width: ${({ fullWidth, maxWidth }) => (fullWidth ? 'none' : maxWidth || '1170px')};
  min-height: calc(100vh - ${FOOTER_HEIGHT.DESKTOP} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.LG});
  margin: 0 auto;
  padding: ${spacing.xlSpacing} 0;
  ${media.smDesktop`
    width: 100%;
    padding: ${spacing.mdSpacing};
  `};
  ${media.tablet`
    min-height: calc(100vh - ${FOOTER_HEIGHT.TABLET} - ${SIZES.PAGE.WEB_NAVBAR_HEIGHT.SM});
  `};
`;

type PageProps = {
  readonly authorized: boolean;
  readonly children: ReactNode;
  readonly footer: ReactNode;
};

const Page = ({ authorized = false, children, footer }: PageProps) => {
  const { isSMScreenOrBigger, isMDScreenOrBigger, isLGScreenOrBigger } = useScreenSize();
  const isOnboardingRoute = useSelectorShared(getIsOnboardingRoute);
  const isHubReferralInfoRequestRoute = useSelectorShared(getIsHubReferralInfoRequestRoute);
  const isOrganicOnboardingLandingPage = useSelectorShared(getIsOrganicOnboardingLandingPage);
  const withoutDashboardNavBar = isOnboardingRoute || isOrganicOnboardingLandingPage || isHubReferralInfoRequestRoute;

  return (
    <Wrapper>
      {authorized ? (
        <AuthorizedPageContainer
          isSMScreenOrBigger={isSMScreenOrBigger}
          isMDScreenOrBigger={isMDScreenOrBigger}
          isLGScreenOrBigger={isLGScreenOrBigger}
          withoutDashboardNavBar={withoutDashboardNavBar}
        >
          {children}
        </AuthorizedPageContainer>
      ) : (
        <UnauthorizedPageContainer
          isSMScreenOrBigger={isSMScreenOrBigger}
          isMDScreenOrBigger={isMDScreenOrBigger}
          isLGScreenOrBigger={isLGScreenOrBigger}
          withoutDashboardNavBar={withoutDashboardNavBar}
        >
          {children}
        </UnauthorizedPageContainer>
      )}
      {footer}
    </Wrapper>
  );
};
export default Page;
