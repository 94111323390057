// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { Button, Card, LgPadding, LgSpacing, MdPadding } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useNavigation } from '@alto/navigation';
import { PatientTasksEndpoint } from '@alto/scriptdash/alto/patient_app/patient_tasks/v1/patient_tasks_endpoint';
import React, { useEffect } from 'react';
import { selectUser } from '~shared/actions/ui/users';
import Calendar from '~shared/assets/images/calendar.png';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { apiEndpointHandler } from '~shared/helpers/api';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useDispatchShared } from '~shared/store';

type Props = {
  readonly userID: number;
  readonly taskID: number;
  readonly onInsuranceDismissal: () => void;
  readonly paddingBottom?: boolean;
};

export const NewYearInsuranceCard = ({ userID, taskID, onInsuranceDismissal, paddingBottom }: Props) => {
  const { navigate } = useNavigation();
  const { trackEvent, trackPageView } = useAnalytics();
  const { value: newYearInsuranceRouteEnabled } = Experimentation.useFeatureFlag('new_year_insurance_route');

  const dispatch = useDispatchShared();

  const title = 'New year, new insurance?';
  const description = 'If your insurance is changing, update your info now to prevent any billing issues.';
  const buttonText = 'Update now';
  const dismissText = 'No insurance changes';

  const handleUpdateInsurance = () => {
    trackEvent({ event: EVENTS.UPDATE_INSURANCE_CLICKED });
    dispatch(selectUser(userID));
    if (newYearInsuranceRouteEnabled) {
      navigate('RouteInsurancePhotoForm', { source: 'new_year_insurance_task', userID });
    } else {
      navigate('RouteInsuranceSection', { userID });
    }
  };

  const handleDismissInsurance = async () => {
    const { data } = await PatientTasksEndpoint(apiEndpointHandler).complete({ task_ids: [taskID] });
    if (data) {
      onInsuranceDismissal();
    }
  };

  useEffect(() => {
    trackPageView({
      event: EVENTS.NEW_YEAR_INSURANCE_TASK_VIEWED,
    });
  }, [trackPageView]);

  return (
    <MdPadding
      topPadding={SPACING.STATIC.NONE}
      bottomPadding={paddingBottom ? SPACING.STATIC.XXL : SPACING.STATIC.NONE}
    >
      <Card
        title={title}
        description={description}
        illustrationAccessibilityLabel={IMG_ALT_TEXTS.insuranceCard}
        illustrationSrc={Calendar}
      >
        <LgPadding
          topPadding={SPACING.STATIC.NONE}
          rightPadding={SPACING.STATIC.NONE}
          leftPadding={SPACING.STATIC.NONE}
        >
          <LgPadding
            topPadding={SPACING.STATIC.NONE}
            bottomPadding={SPACING.STATIC.NONE}
          >
            <Button
              label={buttonText}
              onPress={handleUpdateInsurance}
            />
            <LgSpacing />
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={handleDismissInsurance}
              type="tertiary"
              label={dismissText}
              noPadding
            />
          </LgPadding>
        </LgPadding>
      </Card>
    </MdPadding>
  );
};
