// @owners { team: patients-team }
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';

export const useQueryLandingPageContent = ({
  externalUUID,
  hubReferralID,
}: {
  externalUUID: string;
  hubReferralID: number;
}) => {
  const { isFetching, isSuccess, data, isError } = useQuery({
    ...queries.hubReferralInfoRequestConfigs.fetchLandingPageContent({
      params: {
        external_uuid: externalUUID,
        hub_referral_id: hubReferralID,
      },
    }),
    enabled: !!externalUUID && !!hubReferralID,
    staleTime: staleTimes.fiveMinutes,
  });

  return {
    isFetching,
    isSuccess,
    isError,
    programName: data?.data?.program_name,
    headerLogoUrl: data?.data?.header_logo_url,
    welcomeImageUrl: data?.data?.welcome_image_url,
    welcomeHeader: data?.data?.welcome_header,
    welcomeBody: data?.data?.welcome_body,
    nextURL: data?.data?.next_url,
  };
};
