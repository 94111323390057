// @owners { team: patients-team }
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';

export const useQueryPatientAuthPageContent = ({ hubReferralID }: { hubReferralID: number }) => {
  const { isFetching, isSuccess, data, isError } = useQuery({
    ...queries.hubReferralInfoRequestConfigs.fetchPatientAuthPageContent({
      params: {
        hub_referral_id: hubReferralID,
      },
    }),
    enabled: !!hubReferralID,
    staleTime: staleTimes.fiveMinutes,
  });

  return {
    isFetching,
    isSuccess,
    isError,
    programName: data?.data?.program_name,
    headerLogoUrl: data?.data?.header_logo_url,
    pageHeader: data?.data?.page_header,
    pageBody: data?.data?.page_body,
    authContentTitle: data?.data?.auth_content_title,
    authContentBody: data?.data?.auth_content_body,
    nextURL: data?.data?.next_url,
  };
};
