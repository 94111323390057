// @owners { team: patients-team }
import { ActionSheetContext, Body, Button, LgPadding, Row, Toast, ToastContext } from '@alto/design-system';
import { FSAReceiptsFamilyMemberActionSheet } from '@alto/features';
import { getYear, parseISO } from 'date-fns';
import { stubFalse, times, zipObject } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { getFamilyMembers } from '~shared/features/family-accounts/selectors/getFamilyMembers';
import { getCurrentUser } from '~shared/features/users/selectors/getCurrentUser';
import getDateCreated from '~shared/features/users/selectors/getDateCreated';
import getHasFamily from '~shared/features/users/selectors/getHasFamily';
import { get } from '~shared/helpers/apiHelper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { useSelectorShared } from '~shared/store';

export const FSAReceiptSection = () => {
  const userJoinedAt = useSelectorShared(getDateCreated);
  const { trackEvent } = useAnalytics();
  const hasFamily = useSelectorShared(getHasFamily);
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const { addToast } = useContext(ToastContext);
  const currentUser = useSelectorShared(getCurrentUser);
  const users = useSelectorShared(getFamilyMembers);

  const yearsArray = useMemo(() => {
    const thisYear = getYear(new Date());
    const inception = userJoinedAt ? getYear(parseISO(userJoinedAt)) : thisYear;

    return Array.from(
      {
        length: thisYear - inception + 1,
      },
      (_, i) => i + inception,
    ).reverse();
  }, [userJoinedAt]);

  const [loadingByYear, setLoadingByYear] = useState<Record<string, boolean>>(
    zipObject(yearsArray, times(yearsArray.length, stubFalse)),
  );

  const handleFetchReceipt = async (year: number, userID: number | undefined) => {
    const user = users.find((user) => user.id === userID);
    trackEvent({ event: EVENTS.DOWNLOAD_FSA_ANNUAL_RECEIPT_CLICKED, params: { year, forUser: userID } });
    try {
      setLoadingByYear({ ...loadingByYear, [year]: true });
      const response = await get('/receipts/fsa_receipt', { year, user_id: userID, file_type: 'URI' });
      const downloadLink = document.createElement('a');
      downloadLink.href = response.uri;
      downloadLink.download = `Alto_Invoice_${user?.first_name}-${user?.last_name}-${year}.pdf`;
      downloadLink.click();
    } catch (error) {
      addToast(
        <Toast variant="error">
          Something went wrong. Please try again or message support of the problem persists. {(error as Error).message}
        </Toast>,
      );
    } finally {
      setLoadingByYear({ ...loadingByYear, [year]: false });
    }
  };

  const handleDownloadReceipt = (year: number) => {
    if (hasFamily) {
      setActiveActionSheet(
        <FSAReceiptsFamilyMemberActionSheet
          users={users}
          onPress={(userID: number) => {
            trackEvent({ event: EVENTS.DOWNLOAD_FSA_ANNUAL_RECEIPT_CLICKED, params: { year, forUser: userID } });
            handleFetchReceipt(year, userID);
          }}
        />,
      );
      return;
    }

    trackEvent({ event: EVENTS.DOWNLOAD_FSA_ANNUAL_RECEIPT_CLICKED, params: { year, forUser: currentUser?.id } });
    handleFetchReceipt(year, currentUser?.id);
  };

  return (
    <>
      {yearsArray.map((year) => (
        <LgPadding key={`pdf-download-row-${year}`}>
          <Row
            centerVertically
            spaceBetween
          >
            <Body>{year}</Body>
            <Button
              small
              width="inline"
              onPress={() => {
                handleDownloadReceipt(year);
              }}
              loading={loadingByYear[year]}
              loadingLabel={`Downloading PDF for ${year}`}
            >
              Download PDF
            </Button>
          </Row>
        </LgPadding>
      ))}
    </>
  );
};
