// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { SPACING } from '@alto/design-library-tokens';
import { Card, ListBase, MdPadding, Tag } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { type UpcomingShipment } from '@alto/scriptdash/alto/patient_app/homescreen/types/v1/upcoming_shipment';
import React, { useMemo } from 'react';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import {
  getAsapShipmentFee,
  getOnDemandShipmentFee,
} from '~shared/features/shipment-fees/selectors/getShipmentFeesFromShipment';
import { type StatusMap } from '~shared/features/shipments/constants';
import {
  getShipmentStatusInfoText,
  getShipmentStatusString,
} from '~shared/features/shipments/helpers/getShipmentStatusConfig';
import { useEligibleForMailAutoRefill } from '~shared/features/shipments/hooks/useEligibleForMailAutoRefill';
import { useShipmentStatusMap } from '~shared/features/shipments/hooks/useShipmentStatusMap';
import { type ShipmentWithItemsAndAdditionalFields } from '~shared/features/shipments/types';
import { formatDateWithDowWithoutYear, formatRelativeDateWithDow } from '~shared/helpers/date';
import { isExpensive } from '~shared/helpers/helper';
import { isMail, isPickup } from '~shared/helpers/order';
import { isPickupPastDue } from '~shared/helpers/pickup';
import { type ShipmentStatus } from '~shared/types';
import { useGetDeliveryMethodsByShipmentIDs } from '../../../delivery-methods';
import { FulfillmentMethodRow, HomeToSignRow, LocationRow, MedRow, TimeWindowRow } from '../../../info-rows';
import { DeliveryCardCTA } from './DeliveryCardCTA';

type Props = {
  readonly shipment: ShipmentWithItemsAndAdditionalFields;
  readonly isEditable?: boolean;
  readonly isPastDelivery?: boolean;
};

export const getDeliveryCardTitle = ({
  date,
  delivered_at,
  deliveryMethod,
  status,
  shipmentStatusMap,
}: {
  date?: string;
  delivered_at?: string | null;
  deliveryMethod?: DeliveryMethod;
  status?: ShipmentStatus;
  shipmentStatusMap: StatusMap;
}) => {
  if (delivered_at) {
    return formatRelativeDateWithDow(delivered_at);
  } else if (date) {
    if (deliveryMethod?.min_transit_days !== deliveryMethod?.max_transit_days) {
      return `Expected ${formatRelativeDateWithDow(date)}`;
    } else if (isPickupPastDue({ date, method: deliveryMethod, status })) {
      return `Ready on ${formatDateWithDowWithoutYear(date)}`;
    } else {
      return formatRelativeDateWithDow(date);
    }
  }
  return getShipmentStatusInfoText(status, deliveryMethod, shipmentStatusMap);
};

export const DeliveryCard = ({ shipment, isEditable, isPastDelivery }: Props) => {
  const {
    address,
    date,
    delivered_at,
    deliver_after,
    deliver_before,
    is_pickup,
    items,
    home_to_sign_confirmation,
    safe_place,
    status,
    shipmentID,
    facility_id,
  } = shipment;
  const { deliveryMethodsByShipmentID } = useGetDeliveryMethodsByShipmentIDs([shipmentID]);
  const deliveryMethod = deliveryMethodsByShipmentID[shipmentID];
  const medicationNames = items.map((item) => (item.medication ? item.medication.medicationName : ''));
  const isOnDemandDelivery = !!getOnDemandShipmentFee(shipment);
  const isAsapDelivery = !!getAsapShipmentFee(shipment);
  const prescriptions = items.map((item) => item.prescription);
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const hasControlledItems = items.some((item) => item.isControl);
  const hasExpensiveItems = isExpensive(prescriptions, useNewThreshold);
  const courierAgeRestrictionEnabled = useCourierAgeRestriction(facility_id, deliveryMethod?.mode);
  const shipmentAsUpcoming = {
    id: shipmentID,
    date: shipment.date,
    start_time: null,
    end_time: null,
    address,
    medication_names: medicationNames,
    editable: isEditable,
    family_member_name: null,
    price: null,
    ordered_on_demand: isOnDemandDelivery,
    status,
  } as UpcomingShipment;
  const eligibleForMailAutoRefill = useEligibleForMailAutoRefill({ shipment: shipmentAsUpcoming, deliveryMethod });

  const rows = [
    {
      key: 'calendar',
      component: (
        <TimeWindowRow
          startTime={deliver_after}
          endTime={deliver_before}
          isOnDemandDelivery={isOnDemandDelivery}
          isAsapDelivery={isAsapDelivery}
        />
      ),
      hide:
        status === 'delivered' ||
        isPickup(deliveryMethod?.mode) ||
        isMail(deliveryMethod?.mode) ||
        eligibleForMailAutoRefill,
    },
    {
      key: 'location',
      component: (
        <LocationRow
          address={address}
          isPickup={is_pickup}
          showMap={status !== 'delivered'}
        />
      ),
      hide: !address, //if no address provided, do not show
    },
    {
      key: 'method',
      component: (
        <FulfillmentMethodRow
          method={deliveryMethod}
          status={status}
        />
      ),
      hide: eligibleForMailAutoRefill,
    },
    {
      key: 'meds',
      component: <MedRow medicationNames={medicationNames} />,
    },
    {
      key: 'signature',
      component: isPastDelivery ? null : (
        <HomeToSignRow
          homeToSignConfirmation={home_to_sign_confirmation}
          safePlace={!!safe_place}
          method={deliveryMethod}
          hasControlledMedication={hasControlledItems}
          hasExpensiveMedication={hasExpensiveItems}
          courierAgeRestrictionEnabled={courierAgeRestrictionEnabled}
        />
      ),
      hide: isPickup(deliveryMethod?.mode) || eligibleForMailAutoRefill,
    },
    {
      key: 'view-delivery',
      component: (
        <MdPadding
          topPadding={SPACING.STATIC.LG}
          bottomPadding={SPACING.STATIC.LG}
        >
          <DeliveryCardCTA
            isEditable={isEditable}
            shipmentID={shipmentID}
          />
        </MdPadding>
      ),
    },
  ];

  const lines = rows.filter((row) => !row.hide);

  const shipmentStatusMap = useShipmentStatusMap();
  const deliveryTitle = useMemo(() => {
    return getDeliveryCardTitle({ date, delivered_at, deliveryMethod, status, shipmentStatusMap });
  }, [date, delivered_at, deliveryMethod, status, shipmentStatusMap]);

  return (
    <Card
      title={deliveryTitle}
      tag={
        status !== 'delivered' ? (
          <Tag
            label={getShipmentStatusString(status, shipmentStatusMap, is_pickup)}
            type="recommended"
          />
        ) : undefined
      }
    >
      <ListBase items={lines} />
    </Card>
  );
};
