// @owners { team: patients-team }

import {
  InfoRequestEndpoint,
  type InfoRequestEndpointCreateAuthRequest,
} from '@alto/scriptdash/alto/patient_app/hub_referral/v1/info_request_endpoint';
import { useMutation } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';

const hubReferralInfoRequestEndpoint = InfoRequestEndpoint(apiEndpointHandler);

export const useSignPatientAuthorization = () => {
  return useMutation({
    mutationFn: async (params: InfoRequestEndpointCreateAuthRequest) => {
      const { data, errors } = await hubReferralInfoRequestEndpoint.createAuth(params);
      return {
        patientAuthorizationID: data?.patient_authorization_id,
        errors,
      };
    },
  });
};
