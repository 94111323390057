// @owners { team: patients-team }
import { Toast, ToastContext } from '@alto/design-system';
import { WelcomePage } from '@alto/features';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { push } from 'react-router-redux';
import { loginWeb } from '~shared/actions/auth';
import { DEFAULT_ERROR_ALERT_MESSAGE } from '~shared/constants';
import { getIsLoggedIn } from '~shared/features/auth/selectors/getIsAuthorized';
import getErrors from '~shared/features/ui/selectors/getErrors';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { areCookiesDisabled } from '~shared/helpers/getDeviceID';
import { getURLParams } from '~shared/helpers/helper';
import { useAnalytics } from '~shared/hooks';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import getAuth from '~shared/selectors/auth/getAuth';
import { Sentry } from '~shared/sentry';
import { useDispatchShared, useSelectorShared } from '~shared/store';

export const Welcome = () => {
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const loggedIn = useSelectorShared(getIsLoggedIn);
  const { postLoginRoute } = useSelectorShared(getAuth);
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [otherError, setOtherError] = useState<unknown | null>(null);
  const [email, setEmail] = useState<string>('');
  const hasLoggedInRef = useRef(false);
  const {
    error: auth0Error,
    loginWithRedirect: auth0loginWithRedirect,
    isAuthenticated: isAuth0Authenticated,
    getIdTokenClaims: getAuth0IDTokenClaims,
    isLoading: auth0Loading,
    user: auth0User,
  } = useAuth0();
  const cookiesDisabled = areCookiesDisabled();
  const loginLoading = useSelectorShared(getLoading).loginLoading;
  const loading = auth0Loading || loginLoading;
  const loginError = useSelectorShared(getErrors).loginError;
  const error = loginError || otherError || cookiesDisabled;
  const customErrorMessage = cookiesDisabled ? 'Please enable cookies and refresh page to continue.' : undefined;

  const auth0Login = async () => {
    trackEvent({ event: EVENTS.LOGIN_CLICKED });
    try {
      setOtherError(null);
      // set params for post login routing
      const next = getURLParams().next;
      const appState = next ? { next } : {};
      // open auth0 login page for user to authenticate
      await auth0loginWithRedirect({ appState });
    } catch (e) {
      onLoginError(e);
    }
  };

  // get ID token after auth0 authentication and use to call our own login endpoint
  useEffect(() => {
    const getTokenAndLogin = async () => {
      if (hasLoggedInRef.current) return;
      hasLoggedInRef.current = true;
      Sentry.captureMessage('Login Attempt', (scope) => {
        scope.setLevel('info');
        scope.setTag('auth0_id', auth0User?.sub);
        return scope;
      });
      try {
        const claims = await getAuth0IDTokenClaims();
        const idToken = claims?.__raw || '';
        const email = claims?.email || '';
        if (!idToken) {
          trackEvent({
            event: EVENTS.AUTH0_LOGIN_NO_TOKEN,
          });
        }
        if (!email) {
          trackEvent({
            event: EVENTS.AUTH0_LOGIN_NO_EMAIL,
          });
        }
        setEmail(email);
        dispatch(loginWeb(idToken));
      } catch (e) {
        onLoginError(e);
        hasLoggedInRef.current = false;
      }
    };

    if (auth0Error) {
      Sentry.captureMessage('Error logging in', {
        contexts: { 'Login error': { error: auth0Error, email } },
        level: 'warning',
      });
      addToast(<Toast variant="error">{DEFAULT_ERROR_ALERT_MESSAGE}</Toast>);
      hasLoggedInRef.current = false;
    }

    const params = getURLParams();
    const auth0Redirected = !!(params.code && params.state);
    // including onRedirectCallback property in the Auth0Provider preserves the code and state params,
    // so we can check those to determine if user was just redirected back into app after auth0 authentication
    // (vs just logged out and was redirected to the login page). Setting prompt='login' in the Auth0Provider
    // ensures user will still have to log in via auth0 if loggedIn=false but isAuth0Authenticated=true
    if (isAuth0Authenticated && auth0Redirected && !loggedIn && !hasLoggedInRef.current) {
      getTokenAndLogin();
    }
  }, [
    addToast,
    auth0Error,
    email,
    isAuth0Authenticated,
    getAuth0IDTokenClaims,
    dispatch,
    loggedIn,
    auth0User,
    trackEvent,
  ]);

  const onLoginError = (e: unknown) => {
    const context = { contexts: { 'Login error': { error: e } } };
    Sentry.captureMessage('Error logging in', context);
    setOtherError(e);
  };

  useEffect(() => {
    if (loggedIn) {
      const path = postLoginRoute ?? '/home';
      dispatch(push(path));
    }
  }, [loggedIn, dispatch, postLoginRoute]);

  return (
    <WelcomePage
      login={auth0Login}
      loading={loading}
      error={!!error}
      errorMessage={customErrorMessage}
      navigateToOnboarding={() => dispatch(push({ pathname: '/signup', search: '?origin=login' }))}
      disableLogin={cookiesDisabled}
    />
  );
};
