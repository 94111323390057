// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { NavBarLogo as Logo, XsPadding } from '@alto/design-system';
import React from 'react';

type Props = {
  readonly illustrationSrc?: string;
};

export const NavBarLogo = ({ illustrationSrc }: Props) => {
  if (!illustrationSrc) {
    return null;
  }

  return (
    <>
      <XsPadding
        leftPadding={SPACING.STATIC.NONE}
        bottomPadding={SPACING.STATIC.NONE}
        rightPadding={SPACING.STATIC.NONE}
      >
        <Logo
          source={illustrationSrc}
          accessibilityLabel="info-program-logo"
        />
      </XsPadding>
    </>
  );
};
