// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import { Card, LgPadding, SecondaryPage } from '@alto/design-system';
import React from 'react';
import { withRouter } from 'react-router';
import { goBack } from 'react-router-redux';
import getIsPhotoUpload from '~shared/features/insurances/helpers/getIsPhotoUpload';
import { getInsuranceByID } from '~shared/features/insurances/selectors/getInsuranceById';
import { getEditingOnboardingInsuranceID } from '~shared/features/onboarding/selectors/getOnboarding';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useQueryInsurancePageContent } from '~web/features/info-request/hooks';
import { InsurancePhotoUploadButtons } from '~web/features/onboarding/components/redesign/InsurancePhotoUploadButtons';

type Props = {
  params: {
    externalUUID: string;
    hubReferralID: string;
  };
};

// TODO: https://altopharmacy.atlassian.net/browse/PH-2033?atlOrigin=eyJpIjoiODk3ZDVlZmYwYTNhNDgwODk4NGM4YTFmODljYmViZjIiLCJwIjoiaiJ9
const InsuranceInputPhotoPageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const dispatch = useDispatchShared();
  const loading = useSelectorShared((state) => state.ui.loading.createPhotoInsuranceLoading);
  const insuranceID = useSelectorShared(getEditingOnboardingInsuranceID);
  const insurance = useSelectorShared((state) => getInsuranceByID(state, insuranceID));
  const viewOnly = getIsPhotoUpload(insurance);
  const { isFetching, headerLogoUrl } = useQueryInsurancePageContent({
    hubReferralID,
  });

  const handleGoBack = () => {
    dispatch(goBack());
  };

  if (isFetching) {
    return null;
  }
  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title="Upload a photo of your insurance card"
          subtitle="Include both sides of your benefits card, and take the photos on a neutral background."
        />
      }
    >
      <Card>
        <LgPadding>
          <InsurancePhotoUploadButtons
            loading={loading}
            onFormChange={() => {}}
            initialFrontImageUrl={''}
            initialBackImageUrl={''}
            disabled={viewOnly}
          />
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};

export const InsuranceInputPhotoPage = withRouter(InsuranceInputPhotoPageComponent);
