import { type Store } from 'redux';
import getShouldFlushAnalyticsEvents from '~shared/lib/analytics/src/getShouldFlushAnalyticsEvents';
import { type ReduxStateShared } from '~shared/types';
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type Reactor = (state: ReduxStateShared) => any | null | undefined;

/**
 * Any platform agnostic reactors should be included in the SHARED_REACTORS array.
 * The naming convention for reactors is: getShould{doTheThing}
 */
const SHARED_REACTORS: Reactor[] = [getShouldFlushAnalyticsEvents];

export default function reactors(store: Store<ReduxStateShared>) {
  store.subscribe(() => {
    const state = store.getState();

    for (const reactor of SHARED_REACTORS) {
      const nextReaction = reactor(state);

      if (nextReaction) {
        store.dispatch(nextReaction);
        return;
      }
    }
  });
}
