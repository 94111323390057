// @owners { team: patients-team }
import {
  InfoRequestEndpoint,
  type InfoRequestEndpointCreateCommsConfigRequest,
  type InfoRequestEndpointCreateCommsConfigResponse,
  type InfoRequestEndpointFetchAuthPageContentRequest,
  type InfoRequestEndpointFetchAuthPageContentResponse,
  type InfoRequestEndpointFetchCommsConfigPageContentRequest,
  type InfoRequestEndpointFetchCommsConfigPageContentResponse,
  type InfoRequestEndpointFetchCompletePageContentRequest,
  type InfoRequestEndpointFetchCompletePageContentResponse,
  type InfoRequestEndpointFetchInsurancePageContentRequest,
  type InfoRequestEndpointFetchInsurancePageContentResponse,
  type InfoRequestEndpointFetchLandingPageContentRequest,
  type InfoRequestEndpointFetchLandingPageContentResponse,
} from '@alto/scriptdash/alto/patient_app/hub_referral/v1/info_request_endpoint';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { apiEndpointHandler } from '~shared/helpers/api';
import { getErrorMessageFromResponse } from '~shared/helpers/helper';

const hubReferralInfoRequestEndpoint = InfoRequestEndpoint(apiEndpointHandler);

export const hubReferralInfoRequestConfigs = createQueryKeys('hubReferralInfoRequestConfigs', {
  createCommsConfig: ({ params }: { params: InfoRequestEndpointCreateCommsConfigRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointCreateCommsConfigResponse> => {
      const response = await hubReferralInfoRequestEndpoint.createCommsConfig(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
  fetchCommsConfigPageContent: ({ params }: { params: InfoRequestEndpointFetchCommsConfigPageContentRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointFetchCommsConfigPageContentResponse> => {
      const response = await hubReferralInfoRequestEndpoint.fetchCommsConfigPageContent(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
  fetchCompletePageContent: ({ params }: { params: InfoRequestEndpointFetchCompletePageContentRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointFetchCompletePageContentResponse> => {
      const response = await hubReferralInfoRequestEndpoint.fetchCompletePageContent(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
  fetchLandingPageContent: ({ params }: { params: InfoRequestEndpointFetchLandingPageContentRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointFetchLandingPageContentResponse> => {
      const response = await hubReferralInfoRequestEndpoint.fetchLandingPageContent(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
  fetchPatientAuthPageContent: ({ params }: { params: InfoRequestEndpointFetchAuthPageContentRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointFetchAuthPageContentResponse> => {
      const response = await hubReferralInfoRequestEndpoint.fetchAuthPageContent(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
  fetchInsurancePageContent: ({ params }: { params: InfoRequestEndpointFetchInsurancePageContentRequest }) => ({
    queryKey: [{ params }],
    queryFn: async (): Promise<InfoRequestEndpointFetchInsurancePageContentResponse> => {
      const response = await hubReferralInfoRequestEndpoint.fetchInsurancePageContent(params);
      if (response.errors) {
        throw new Error(getErrorMessageFromResponse({ errors: response.errors }));
      }
      return response;
    },
  }),
});
