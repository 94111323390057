// @owners { team: patients-team }
import { createSelector } from 'reselect';
import { type ReduxStateShared } from '~shared/types';

const getPathname = (state: ReduxStateShared): string | null | undefined =>
  state.routing.locationBeforeTransitions?.pathname || window.location.pathname;

export const getIsHubReferralInfoRequestRoute: (arg0: ReduxStateShared) => boolean = createSelector(
  [getPathname],
  (pathname: string | null | undefined) => {
    return Boolean(pathname?.startsWith('/info/'));
  },
);
