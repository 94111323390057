// @owners { team: patients-team }
import { SPACING } from '@alto/design-library-tokens';
import { ActionSheetV2, LgPadding, isSMScreenOrBigger } from '@alto/design-system';
import React from 'react';
import { PatientAuthorizationForm } from './PatientAuthorizationForm';

type Props = {
  readonly hubReferralID: number;
  readonly onClose: () => void;
  readonly nextURL?: string;
};

export const PatientAuthorizationActionSheet = ({ hubReferralID, onClose, nextURL }: Props) => {
  const isDesktopWeb = isSMScreenOrBigger();

  return (
    <ActionSheetV2
      title="Submit your electronic signature"
      analyticsName="hub referral info request patient authorization"
    >
      <LgPadding bottomPadding={isDesktopWeb ? SPACING.STATIC.NONE : SPACING.STATIC.LG}>
        <PatientAuthorizationForm
          buttonLabel="Submit electronic signature"
          hubReferralID={hubReferralID}
          onClose={onClose}
          nextURL={nextURL}
        />
      </LgPadding>
    </ActionSheetV2>
  );
};
