// @owners { team: patients-team }
import { COLORS } from '@alto/design-library-tokens';
import {
  AltoSpinningLoader,
  Button,
  Card,
  InputRadio,
  InputRadioGroup,
  LgPadding,
  Row,
  SecondaryPage,
  Toast,
  ToastContext,
} from '@alto/design-system';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
import { useDispatchShared } from '~shared/store';
import { InfoRequestHeader } from './InfoRequestHeader';
import { NavBarLogo } from './NavBarLogo';
import { useCreateCommsConfig, useQueryCommsConfigPageContent } from '~web/features/info-request/hooks';

type Props = {
  params: {
    hubReferralID: string;
  };
};

const CommsConfigPageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const [value, setValue] = useState<string | null>(null);
  const dispatch = useDispatchShared();
  const { addToast } = useContext(ToastContext);
  const { isFetching, headerLogoUrl, pageBody, pageHeader, nextURL } = useQueryCommsConfigPageContent({
    hubReferralID,
  });
  const { mutateAsync: createCommsConfig } = useCreateCommsConfig();
  const handleValueChange = (value: string) => {
    setValue(value);
  };

  const handleGoBack = () => {
    dispatch(goBack());
  };

  const handleSubmit = async () => {
    const params = {
      hub_referral_id: hubReferralID,
      has_sms_opted_out: value === 'no',
    };

    const response = await createCommsConfig(params);
    if (response.errors) {
      addToast(<Toast variant="error">Failed to save messaging preferences. Please try again.</Toast>);
    } else {
      addToast(<Toast variant="success">Messaging preferences successfully saved.</Toast>);
    }

    if (nextURL) {
      dispatch(push(nextURL));
    }
  };

  if (isFetching) {
    return (
      <Row rowCenter>
        <AltoSpinningLoader />
      </Row>
    );
  }

  if (!pageHeader) {
    return null;
  }

  return (
    <SecondaryPage
      footerPlacementContext="webScreenWithoutPolicyFooter"
      withoutWebFramingElementHeights
      headerBackgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      dismissIcon="chevronleft"
      onDismiss={handleGoBack}
      NavBarCenterContent={headerLogoUrl ? <NavBarLogo illustrationSrc={headerLogoUrl} /> : undefined}
      HeaderContent={
        <InfoRequestHeader
          title={pageHeader}
          subtitle={pageBody}
        />
      }
      buttons={[
        <Button
          label="Submit"
          key="submit"
          onPress={handleSubmit}
          disabled={!value}
        />,
      ]}
    >
      <Card>
        <LgPadding>
          <InputRadioGroup
            onValueChange={handleValueChange}
            defaultValue="1"
          >
            <InputRadio
              key="yes"
              label="Yes"
              value="yes"
            />
            <InputRadio
              key="no"
              label="No"
              value="no"
            />
          </InputRadioGroup>
        </LgPadding>
      </Card>
    </SecondaryPage>
  );
};

export const CommsConfigPage = withRouter(CommsConfigPageComponent);
