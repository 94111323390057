// @owners { team: patients-team }
import { ActionSheetContext, InputSelect } from '@alto/design-system';
import React, { useCallback, useContext, useEffect } from 'react';
import { updateOrder } from '~shared/actions/cart';
import { clearPaymentMethodError } from '~shared/actions/paymentMethods';
import { updateTipPaymentMethodID } from '~shared/actions/ui/shipmentTipInput';
import { PAYMENT_METHOD_TYPES, type PaymentMethodType } from '~shared/constants';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { getOrder } from '~shared/features/checkout/selectors/getOrder';
import { getDisabledPaymentMethodID, getSelectedPaymentMethodId } from '~shared/features/courier-tips/helpers';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { getDefaultPaymentMethodID } from '~shared/features/payment-methods/helpers';
import {
  getDidAttemptOrderUpdate,
  getIsEditingExistingOrder,
  getIsSplitPaymentEnabled,
} from '~shared/features/ui/selectors/getCart';
import { getCurrentUserID } from '~shared/features/users/selectors/getUsers';
import { useAnalytics } from '~shared/hooks';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { createEvent } from '~shared/lib/analytics/src/helper';
import { type Event } from '~shared/lib/analytics/src/types';
import { useGetPaymentMethods } from '~shared/queries/query-keys/paymentMethods';
import { getTipPaymentMethodIDInput } from '~shared/selectors/ui/shipmentTipInput/getShipmentTipInput';
import { useDispatchShared, useSelectorShared } from '~shared/store';
import { AddPaymentMethodActionSheet } from './AddPaymentMethodActionSheet';
import { usePaymentOptions } from './usePaymentOptions';

type Props = {
  readonly handleNativePay?: (paymentMethodType: PaymentMethodType) => void;
  readonly isSecondaryPaymentMethod?: boolean;
  readonly paymentMethodType: PaymentMethodType;
  readonly disabled?: boolean;
};

export const PaymentMethodSelector = ({
  handleNativePay,
  isSecondaryPaymentMethod,
  paymentMethodType,
  disabled,
}: Props) => {
  const dispatch = useDispatchShared();
  const order = useSelectorShared(getOrder);
  const { trackEvent } = useAnalytics();
  const userID = useSelectorShared(getCurrentUserID);
  const shipmentTip = useSelectorShared((state) =>
    getShipmentTipForShipmentID(state, {
      shipmentID: getEditShipmentID(state),
    }),
  );
  const postTipPaymentMethodID = useSelectorShared(getTipPaymentMethodIDInput);
  const isSplitPaymentEnabled = useSelectorShared(getIsSplitPaymentEnabled);
  const selectedPaymentMethodID = getSelectedPaymentMethodId({
    order,
    shipmentTip,
    paymentMethodType,
    postTipPaymentMethodID,
    isSecondaryPaymentMethod,
  });
  const { setActiveActionSheet } = useContext(ActionSheetContext);
  const saveOrderError = useSelectorShared((state) => state.ui.errors.saveOrderError);
  const { isPending: isFetchingPaymentMethods } = useGetPaymentMethods();
  const createPaymentMethodLoading = useSelectorShared((state) => state.ui.loading.createPaymentMethodLoading);
  const didAttemptOrderUpdate = useSelectorShared(getDidAttemptOrderUpdate);
  const isEditingOrder = useSelectorShared(getIsEditingExistingOrder);
  const { paymentMethods } = useGetPaymentMethods();
  const disabledPaymentMethodID = getDisabledPaymentMethodID({
    order,
    isSecondaryPaymentMethod,
    isSplitPaymentEnabled,
  });
  const options = usePaymentOptions({
    paymentMethods,
    order,
    saveOrderError,
    isEditingOrder,
    didAttemptOrderUpdate,
    isSplitPaymentEnabled,
    disabledPaymentMethodID,
    onNewPress: useCallback(() => {
      trackEvent({ event: EVENTS.ADD_NEW_METHOD_TAPPED });
      setActiveActionSheet(
        <AddPaymentMethodActionSheet
          handleNativePay={handleNativePay}
          paymentMethodType={paymentMethodType}
        />,
      );
    }, [handleNativePay, paymentMethodType, trackEvent, setActiveActionSheet]),
  });

  const handleSelectPaymentMethod = useCallback(
    (id: string) => {
      const paymentMethod = paymentMethods.find((pm) => pm.id === Number(id));
      if (!paymentMethod) return;
      if (paymentMethodType === PAYMENT_METHOD_TYPES.PRE_TIP) {
        const { tip_payment_method_id } = order;
        if (paymentMethod.id !== tip_payment_method_id) {
          dispatch(
            updateOrder({
              tip_payment_method_id: paymentMethod.id,
            }),
          );
        }
        return;
      }
      if (paymentMethodType === PAYMENT_METHOD_TYPES.POST_TIP) {
        if (postTipPaymentMethodID !== paymentMethod.id) {
          dispatch(updateTipPaymentMethodID(paymentMethod.id));
        }
        return;
      }
      const paymentIDKey = isSecondaryPaymentMethod ? 'second_payment_method_id' : 'payment_method_id';
      const paymentMethodID = order[paymentIDKey];
      if (paymentMethod.id !== paymentMethodID) {
        const eventData = {
          user_id: userID,
          split_payment_toggle_on: isSplitPaymentEnabled,
          add_new_payment_method: false,
        };
        if (!isSecondaryPaymentMethod) eventData.add_new_payment_method = true;
        const eventType = isSecondaryPaymentMethod
          ? EVENTS.SPLIT_PAYMENTS_ADD_SECOND_PAYMENT
          : EVENTS.SPLIT_PAYMENTS_ADD_NEW_PAYMENT;
        const event: Event = createEvent(eventType, eventData);
        dispatch(sendAnalyticsEvent(event));
        const newPayment = {
          [paymentIDKey]: paymentMethod.id,
        };
        dispatch(updateOrder(newPayment));
      }
    },
    [
      dispatch,
      isSecondaryPaymentMethod,
      isSplitPaymentEnabled,
      order,
      paymentMethodType,
      paymentMethods,
      postTipPaymentMethodID,
      userID,
    ],
  );

  useEffect(() => {
    return () => {
      dispatch(clearPaymentMethodError());
    };
  }, [dispatch]);

  const { pay_at_pickup } = order;
  const defaultPaymentMethodID = getDefaultPaymentMethodID(paymentMethods) ?? undefined;

  // auto-select a payment method for the payment type when one is not selected
  useEffect(() => {
    if (selectedPaymentMethodID || pay_at_pickup || !defaultPaymentMethodID) return;

    if (paymentMethodType === 'PRE_TIP') {
      dispatch(updateOrder({ tip_payment_method_id: defaultPaymentMethodID }));
    }

    if (paymentMethodType === 'PRIMARY') {
      dispatch(updateOrder({ payment_method_id: defaultPaymentMethodID }));
    }

    if (paymentMethodType === 'SECONDARY') {
      dispatch(updateOrder({ second_payment_method_id: defaultPaymentMethodID }));
    }
  }, [defaultPaymentMethodID, dispatch, pay_at_pickup, paymentMethodType, selectedPaymentMethodID]);

  return (
    <InputSelect
      disabled={disabled}
      value={selectedPaymentMethodID?.toString()}
      options={options}
      onValueChange={handleSelectPaymentMethod}
      placeholder="Select payment method"
      modalTitle="Select payment method"
      loading={isFetchingPaymentMethods || createPaymentMethodLoading}
      required
    />
  );
};
