// @owners { team: patients-team }

import {
  InfoRequestEndpoint,
  type InfoRequestEndpointCreateCommsConfigRequest,
} from '@alto/scriptdash/alto/patient_app/hub_referral/v1/info_request_endpoint';
import { useMutation } from '@tanstack/react-query';
import { apiEndpointHandler } from '~shared/helpers/api';

const hubReferralInfoRequestEndpoint = InfoRequestEndpoint(apiEndpointHandler);

export const useCreateCommsConfig = () => {
  return useMutation({
    mutationFn: async (params: InfoRequestEndpointCreateCommsConfigRequest) => {
      const { data, errors } = await hubReferralInfoRequestEndpoint.createCommsConfig(params);
      return {
        patientPartnershipCommsConfigID: data?.patient_partnership_comms_config_id,
        errors,
      };
    },
  });
};
