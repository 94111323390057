// @owners { team: supply-chain }
import { DeliveryMethodTypeMap } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method_type';
import { type ModeType, ModeTypeMap } from '@alto/deliver_api/types/delivery_methods/v1/mode_type';
import { Experimentation } from '@alto/experimentation';
import { getAddress } from '~shared/features/addresses/selectors/getAddress';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { getOrderFacilityId } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { isCourier } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { type LightDelivery } from '~shared/types';

export type CourierAgeRestriction = {
  enabledFacilityIds: number[];
};

export const defaultCourierAgeRestriction: CourierAgeRestriction = {
  enabledFacilityIds: [],
};

export const useCourierAgeRestriction = (
  facilityId: number | undefined,
  deliveryMethodMode: ModeType | undefined,
): boolean => {
  const { value } = Experimentation.useFeatureFlag('courier_age_restriction_clients', defaultCourierAgeRestriction);

  if (!isCourier(deliveryMethodMode)) return false;
  if (!facilityId) return false;
  return (value as CourierAgeRestriction).enabledFacilityIds.includes(facilityId);
};

export const useCourierAgeRestrictionByDelivery = (delivery: LightDelivery | undefined): boolean => {
  // on the edit order page, a non-undefined delivery will be passed in
  // we will use the facility id from the delivery's address
  const address = useSelectorShared((state) => getAddress(state, { addressID: delivery?.address_id }));

  // on the checkout page, the delivery is undefined
  // we will be getting both facility id and delivery method mode from the order
  const orderFacilityID = useSelectorShared(getOrderFacilityId);
  const orderIndex = useSelectorShared(getOrderIndex);
  const { deliveryMethods } = useCartDeliveryMethods();

  const { facililyID, deliveryMethodMode } =
    delivery && address
      ? {
          facililyID: address.dispensing_facility_id,
          deliveryMethodMode:
            delivery.delivery_method === DeliveryMethodTypeMap.COURIER ? ModeTypeMap.COURIER : undefined,
        }
      : {
          facililyID: orderFacilityID,
          deliveryMethodMode: deliveryMethods[orderIndex]?.mode,
        };

  return useCourierAgeRestriction(facililyID, deliveryMethodMode);
};
