// @owners { team: patients-team }
import { COLORS, TYPOGRAPHY } from '@alto/design-library-tokens';
// eslint-disable-next-line @alto/no-pocky-import
import {
  Button,
  Column,
  DeprecatedModal,
  DeprecatedModalBody,
  DeprecatedModalFooter,
  LoadingButton,
  createValidator,
  spacing,
  validatePresentWithMessage,
} from '@alto/pocky';
// eslint-disable-next-line @alto/prefer-react-hook-form
import { Form, Formik, type FormikProps } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { type ModalType } from '~shared/actions/modal';
import { type AsyncValue, formatAsyncSelectInitialValue } from '~shared/helpers/formatAsyncSelectValues';
import { type User } from '~shared/types';
import { type APIError } from '~shared/types/APIError';
import AllergiesField from './AllergiesField';
import MedicalConditionsField from './MedicalConditionsField';
import OnboardingOtherScriptsFields from './OnboardingOtherScriptsFields';

type Values = {
  allergies: string;
  medical_conditions: string;
  onboarding_other_scripts: string;
  other_scripts_list: (AsyncValue | null | undefined) | (AsyncValue[] | null | undefined);
};

const validate = createValidator({
  allergies: validatePresentWithMessage('Allergies'),
  medical_conditions: validatePresentWithMessage('Medical conditions'),
});

const FormHelper = styled.div`
  font-family: ${TYPOGRAPHY.FONT.BODY.REGULAR};
  font-size: ${TYPOGRAPHY.TEXT.DETAIL.LG.px};
  color: ${COLORS.PALETTE.DANGER.DEFAULT};
  letter-spacing: 0.49px;
  margin-bottom: 0;
`;

export type Props = {
  readonly firstName: string;
  readonly onClose: () => void;
  readonly user: User;
  readonly onSubmit: () => void;
  readonly modalType: ModalType;
  readonly show: boolean;
  readonly loading: boolean;
  readonly userError: APIError | null;
  readonly markPatientsOtherScriptsExposure: () => void;
};

const MedicalInfoModal = ({
  firstName,
  user,
  onClose,
  modalType,
  show,
  loading,
  userError,
  onSubmit,
  markPatientsOtherScriptsExposure,
}: Props) => {
  useEffect(() => {
    markPatientsOtherScriptsExposure(); //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shown = show && modalType === 'MEDICAL_INFO_MODAL';

  // Prevent modal from rendering until user has loaded,
  // this ensures the formik initial values are correct
  if (!user) return null;

  const { allergies, medical_conditions, onboarding_other_scripts, other_scripts_list } = user;

  return (
    <Formik
      initialValues={{
        allergies,
        medical_conditions,
        onboarding_other_scripts,
        other_scripts_list: formatAsyncSelectInitialValue(other_scripts_list),
      }}
      validate={validate} // This prevents validation error form being shown after clicking textarea for first time
      validateOnBlur={false}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {/* @ts-expect-error something is up with formik here */}
      {(formikProps: FormikProps<Values>) => {
        const { handleReset } = formikProps;

        const handleClose = () => {
          handleReset();
          onClose();
        };

        return (
          shown && (
            <Form
              {...formikProps}
              noValidate
            >
              <DeprecatedModal
                showing={shown}
                onClose={handleClose}
                title={firstName ? `Edit Medical Info (for ${firstName})` : 'Edit Medical Info'}
                scrollable
              >
                <DeprecatedModalBody>
                  {userError ? <FormHelper>{userError.message}</FormHelper> : null}
                  <Column
                    growToParentWidth
                    spacing={spacing.smSpacing}
                  >
                    <AllergiesField />
                    <MedicalConditionsField />
                    <OnboardingOtherScriptsFields />
                  </Column>
                </DeprecatedModalBody>
                <DeprecatedModalFooter>
                  <Button
                    kind="tertiary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </DeprecatedModalFooter>
              </DeprecatedModal>
            </Form>
          )
        );
      }}
    </Formik>
  );
};

export default MedicalInfoModal;
