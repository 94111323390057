import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { type SPACING_STATIC_SIZE } from '@alto/design-library-tokens';
import { Description } from '@alto/design-system';
import React from 'react';
import { useCartDeliveryMethods } from '~shared/features/cart/queries/useCartDeliveryMethods';
import { ADDRESS_MESSAGES } from '~shared/features/checkout/constants';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import { getIsOrderPickupMethod, getOrderFacilityId } from '~shared/features/checkout/selectors/getOrder';
import { getOrderIndex } from '~shared/features/checkout/selectors/getOrdersByIndex';
import { isMail } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { useCheckoutAddressInfoKeys } from '../../../hooks/useCheckoutAddressInfoKeys';
import { useSignatureConfirmation } from '../../../hooks/useSignatureConfirmation';
import { AddressInfoAlert } from './AddressInfoAlert';

export const AddressInfo = ({ topPadding }: { topPadding?: SPACING_STATIC_SIZE }) => {
  const orderIndex = useSelectorShared(getOrderIndex);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const orderFacilityID = useSelectorShared(getOrderFacilityId);
  const { keys: addressInfoKeys, isOutOfZone } = useCheckoutAddressInfoKeys();
  const { deliveryMethods } = useCartDeliveryMethods();
  const deliveryMethod: DeliveryMethod | undefined = deliveryMethods[orderIndex];
  const { homeToSignConfirmationRequired } = useSignatureConfirmation();
  const courierAgeRestrictionEnabled = useCourierAgeRestriction(orderFacilityID, deliveryMethod?.mode);

  // if the address is out of zone or the patient needs to confirm the signature this information will appear elsewhere
  // if the patient selects the pickup option, they don't need to be home or sign for the package
  if (isOutOfZone || homeToSignConfirmationRequired || isOrderPickupMethod) return null;

  if (addressInfoKeys.has_controls) {
    return (
      <AddressInfoAlert
        type="warning"
        topPadding={topPadding}
      >
        <Description>
          {courierAgeRestrictionEnabled
            ? ADDRESS_MESSAGES.a_signature_and_18_plus_photo_id_required
            : ADDRESS_MESSAGES.a_signature_required}
        </Description>
      </AddressInfoAlert>
    );
  }

  if (isMail(deliveryMethod?.mode)) {
    return null;
  }

  return (
    <AddressInfoAlert
      type="neutral"
      topPadding={topPadding}
    >
      <Description>{ADDRESS_MESSAGES.no_need_to_be_home}</Description>
    </AddressInfoAlert>
  );
};
