// Actions should look like this
// https://github.com/acdlite/flux-standard-action
import getLoading from '~shared/features/ui/selectors/getLoading';
import { type ApiOptions } from '~shared/helpers/api';
import { destroy, get, post, put } from '~shared/helpers/apiHelper';
import { type Address, type ReduxDispatch, type ReduxGetStateShared } from '~shared/types';
import { type APIError } from '~shared/types/APIError';

const V3_API_VERSION = 'v3';
const v3Options: ApiOptions = {
  version: V3_API_VERSION,
};

export const FETCH_ADDRESSES_LOADING = 'FETCH_ADDRESSES_LOADING';
export type ActionFetchAddressesLoading = {
  type: typeof FETCH_ADDRESSES_LOADING;
};

export function fetchingAddresses(): ActionFetchAddressesLoading {
  return {
    type: FETCH_ADDRESSES_LOADING,
  };
}

export const FETCH_ADDRESSES_SUCCEEDED = 'FETCH_ADDRESSES_SUCCEEDED';
export type ActionFetchAddressesSucceeded = {
  type: typeof FETCH_ADDRESSES_SUCCEEDED;
  payload: Address[];
};

export function fetchAddressesSucceeded(addresses: Address[]): ActionFetchAddressesSucceeded {
  return {
    type: FETCH_ADDRESSES_SUCCEEDED,
    payload: addresses,
  };
}

export const FETCH_ADDRESSES_FAILED = 'FETCH_ADDRESSES_FAILED';
export type ActionFetchAddressesFailed = {
  type: typeof FETCH_ADDRESSES_FAILED;
  payload: APIError;
  error: true;
};

export function fetchAddressesFailed(error: APIError): ActionFetchAddressesFailed {
  return {
    type: FETCH_ADDRESSES_FAILED,
    payload: error,
    error: true,
  };
}

export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export type ActionSelectAddress = {
  type: typeof SELECT_ADDRESS;
  payload: Address;
};

export function selectAddress(address: Address): ActionSelectAddress {
  return {
    type: SELECT_ADDRESS,
    payload: address,
  };
}

export const DESELECT_ADDRESS = 'DESELECT_ADDRESS';
export type ActionDeselectAddress = {
  type: typeof DESELECT_ADDRESS;
};

export function deselectAddress(): ActionDeselectAddress {
  return {
    type: DESELECT_ADDRESS,
  };
}

export const CREATE_ADDRESS_LOADING = 'CREATE_ADDRESS_LOADING';
export type ActionCreateAddressLoading = {
  type: typeof CREATE_ADDRESS_LOADING;
};

export function creatingAddress(): ActionCreateAddressLoading {
  return {
    type: CREATE_ADDRESS_LOADING,
  };
}

export const CREATE_ADDRESS_SUCCEEDED = 'CREATE_ADDRESS_SUCCEEDED';
export type ActionCreateAddressSucceeded = {
  type: typeof CREATE_ADDRESS_SUCCEEDED;
  payload: Address;
};

export function createAddressSucceeded(address: Address): ActionCreateAddressSucceeded {
  return {
    type: CREATE_ADDRESS_SUCCEEDED,
    payload: address,
  };
}

export const CREATE_ADDRESS_FAILED = 'CREATE_ADDRESS_FAILED';
export type ActionCreateAddressFailed = {
  type: typeof CREATE_ADDRESS_FAILED;
  payload: APIError;
  error: true;
};

export function createAddressFailed(error: APIError): ActionCreateAddressFailed {
  return {
    type: CREATE_ADDRESS_FAILED,
    payload: error,
    error: true,
  };
}

export const UPDATE_ADDRESS_LOADING = 'UPDATE_ADDRESS_LOADING';
export type ActionUpdateAddressLoading = {
  type: 'UPDATE_ADDRESS_LOADING';
};

export function updatingAddress(): ActionUpdateAddressLoading {
  return {
    type: UPDATE_ADDRESS_LOADING,
  };
}

export const UPDATE_ADDRESS_SUCCEEDED = 'UPDATE_ADDRESS_SUCCEEDED';
export type ActionUpdateAddressSucceeded = {
  type: 'UPDATE_ADDRESS_SUCCEEDED';
  payload: Address[];
};

export function updateAddressSucceeded(addresses: Address[]): ActionUpdateAddressSucceeded {
  return {
    type: UPDATE_ADDRESS_SUCCEEDED,
    payload: addresses,
  };
}

export const UPDATE_ADDRESS_FAILED = 'UPDATE_ADDRESS_FAILED';
export type ActionUpdateAddressFailed = {
  type: typeof UPDATE_ADDRESS_FAILED;
  payload: APIError;
  error: true;
};

export function updateAddressFailed(error: APIError): ActionUpdateAddressFailed {
  return {
    type: UPDATE_ADDRESS_FAILED,
    payload: error,
    error: true,
  };
}

export const DELETE_ADDRESS_LOADING = 'DELETE_ADDRESS_LOADING';
export type ActionDeleteAddressLoading = {
  type: typeof DELETE_ADDRESS_LOADING;
};

export function deletingAddress(): ActionDeleteAddressLoading {
  return {
    type: DELETE_ADDRESS_LOADING,
  };
}

export const DELETE_ADDRESS_SUCCEEDED = 'DELETE_ADDRESS_SUCCEEDED';
export type ActionDeleteAddressSucceeded = {
  type: typeof DELETE_ADDRESS_SUCCEEDED;
  payload: Address;
};

export function deleteAddressSucceeded(address: Address): ActionDeleteAddressSucceeded {
  return {
    type: DELETE_ADDRESS_SUCCEEDED,
    payload: address,
  };
}

export const DELETE_ADDRESS_FAILED = 'DELETE_ADDRESS_FAILED';
export type ActionDeleteAddressFailed = {
  type: typeof DELETE_ADDRESS_FAILED;
  payload: APIError;
  error: true;
};

export function deleteAddressFailed(error: APIError): ActionDeleteAddressFailed {
  return {
    type: DELETE_ADDRESS_FAILED,
    payload: error,
    error: true,
  };
}

export const CLEAR_ADDRESS_ERROR = 'CLEAR_ADDRESS_ERROR';
export type ActionClearAddressError = {
  type: typeof CLEAR_ADDRESS_ERROR;
};

export function clearAddressError(): ActionClearAddressError {
  return {
    type: CLEAR_ADDRESS_ERROR,
  };
}

type FetchAddressActions = ActionFetchAddressesLoading | ActionFetchAddressesFailed | ActionFetchAddressesSucceeded;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function fetchAddresses() {
  return async (dispatch: ReduxDispatch, getState: ReduxGetStateShared) => {
    const state = getState();
    const { fetchAddressesLoading } = getLoading(state);

    if (fetchAddressesLoading) {
      return Promise.resolve(true);
    }

    dispatch(fetchingAddresses());
    return get('/addresses').then((json) => {
      if (json.error) {
        dispatch(fetchAddressesFailed(json.error));
        return false;
      }

      dispatch(fetchAddressesSucceeded(json));
      return true;
    });
  };
}

type CreateAddressActions = ActionCreateAddressLoading | ActionCreateAddressFailed | ActionCreateAddressSucceeded;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function createAddress(address: Omit<Address, 'id'>) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(creatingAddress());
    return post('/addresses', {
      address,
    }).then((json: Address & { error: APIError }) => {
      if (json.error) {
        dispatch(createAddressFailed(json.error));
        return { success: false };
      }

      dispatch(createAddressSucceeded(json));
      return { success: true, newAddress: json };
    });
  };
}

type UpdateAddressActions =
  | ActionUpdateAddressLoading
  | ActionUpdateAddressFailed
  | ActionUpdateAddressSucceeded
  | ActionDeselectAddress;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function updateAddress(address: Address) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(updatingAddress());
    return put(`/addresses/${address.id}`, address, v3Options).then((json) => {
      if (json.error) {
        dispatch(updateAddressFailed(json.error));
        return { success: false };
      }

      dispatch(updateAddressSucceeded(json));
      dispatch(deselectAddress());
      return { success: true, json };
    });
  };
}

type DeleteAddressActions = ActionDeleteAddressLoading | ActionDeleteAddressFailed | ActionDeleteAddressSucceeded;

/**
 * @deprecated Redux networking action.
 * @see https://www.notion.so/alto/Guidebook-Migrating-Redux-Networking-Actions-To-React-Query-8567e05fc96c46fcb8020595f24b0edc
 */
export function deleteAddress(addressID: number) {
  return async (dispatch: ReduxDispatch) => {
    dispatch(deletingAddress());
    return destroy(`/addresses/${addressID}`).then((json) => {
      if (json.error) {
        dispatch(deleteAddressFailed(json.error));
        return false;
      }

      dispatch(deleteAddressSucceeded(json));
      return true;
    });
  };
}

export type AddressActions =
  | DeleteAddressActions
  | UpdateAddressActions
  | CreateAddressActions
  | FetchAddressActions
  | ActionSelectAddress
  | ActionDeselectAddress;
