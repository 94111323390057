import { isSameDay, parseISO } from 'date-fns';
import { formatRelativeDateWithDow } from '~shared/helpers/date';

export const getNextAvailableDateTitle = (earliestAvailableDate: string | null) => {
  if (!earliestAvailableDate) {
    return 'Next Available Date';
  }

  const isToday = isSameDay(new Date(), parseISO(earliestAvailableDate));
  return isToday ? 'Available Today' : `Available ${formatRelativeDateWithDow(earliestAvailableDate, true)}`;
};
