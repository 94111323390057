import { Button, Description, InlineAlert, MdPadding, Toast, ToastContext } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import { useCreateLinkToken } from '@alto/features';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { type PlaidLinkError, type PlaidLinkOnExitMetadata, usePlaidLink } from 'react-plaid-link';
import { createBankAccountPaymentMethod } from '~shared/actions/paymentMethods';
// eslint-disable-next-line import/no-deprecated
import { createPlaidLinkToken } from '~shared/actions/plaid';
import { PLAID_ENV, PLAID_PUBLIC_KEY } from '~shared/config';
import { useAnalytics } from '~shared/hooks/useAnalytics';
import { sendAnalyticsEvent } from '~shared/lib/analytics/src/actions';
import { EVENTS } from '~shared/lib/analytics/src/constants';
import { type PlaidEventMetadata, type PlaidEventName, getPlaidEvent } from '~shared/lib/analytics/src/getPlaidEvent';
import { queries } from '~shared/queries/query-keys';
import { Sentry } from '~shared/sentry/index.web';
import { useDispatchShared, useSelectorShared } from '~shared/store';

type PlaidMetadata = {
  account_id: string;
  institution: {
    name: string;
  };
};

export const PlaidLinkButton = () => {
  const queryClient = useQueryClient();
  const { value: isPlaidLinkTokenEnabled, isLoading } = Experimentation.useFeatureFlag('plaid_link_tokens');
  const dispatch = useDispatchShared();
  const { trackEvent } = useAnalytics();
  const { addToast } = useContext(ToastContext);
  const { createLinkToken } = useCreateLinkToken();
  const { token } = useSelectorShared((state) => state.paymentMethods.plaidLinkToken);
  const [plaidError, setPlaidError] = useState<PlaidLinkError | null>(null);

  useEffect(() => {
    if (token || isLoading) return;

    if (isPlaidLinkTokenEnabled) {
      createLinkToken();
    } else {
      // eslint-disable-next-line import/no-deprecated
      dispatch(createPlaidLinkToken());
    }
  }, [createLinkToken, dispatch, isLoading, isPlaidLinkTokenEnabled, token]);

  const onSuccess = useCallback(
    async (publicToken: string, metadata: PlaidMetadata) => {
      const success = await dispatch(
        createBankAccountPaymentMethod({
          publicToken,
          accountId: metadata.account_id,
          institutionName: metadata.institution.name,
        }),
      );

      if (success) {
        queryClient.invalidateQueries({ queryKey: queries.paymentMethods.fetchAll._def });
        trackEvent({
          event: EVENTS.PAYMENT_METHOD_CREATED,
          params: {
            newStripeFormEnabled: false,
            type: 'PlaidData',
          },
        });
      } else {
        trackEvent({
          event: EVENTS.CREATE_PAYMENT_METHOD_FAILED,
          params: {
            newStripeFormEnabled: false,
          },
        });
      }
    },
    [dispatch, queryClient, trackEvent],
  );

  const sendPlaidAnalytics = (eventName: PlaidEventName, metadata: PlaidEventMetadata) => {
    const event = getPlaidEvent(eventName, metadata);
    if (event) {
      dispatch(sendAnalyticsEvent(event));
    }
  };

  const handlePlaidExit = (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    if (!error) return;

    setPlaidError(error);
    if (error.error_code === 'INVALID_LINK_TOKEN') {
      createLinkToken();
      addToast(<Toast variant="neutral">{error.display_message}. Please try adding your account again.</Toast>);
      return;
    }

    Sentry.captureMessage('Error linking account via Plaid', {
      tags: { featureOwner: 'patients-team' },
      contexts: { 'Plaid Error': { error, metadata } },
      level: 'warning',
    });
    addToast(
      <Toast variant="error">{`${error.display_message}. Please try again or message support if the problem persists.`}</Toast>,
    );
  };

  // Use PlaidLinkOptionsWithLinkToken type when feature flag enabled, otherwise PlaidLinkOptionsWithPublicKey type
  const config = isPlaidLinkTokenEnabled
    ? {
        token,
        onEvent: sendPlaidAnalytics,
        onExit: handlePlaidExit,
        onSuccess,
      }
    : {
        clientName: 'Alto',
        env: PLAID_ENV,
        onEvent: sendPlaidAnalytics,
        onSuccess,
        product: ['auth'],
        publicKey: PLAID_PUBLIC_KEY,
      };

  // @ts-expect-error eventName types not compatible but handled
  const { open, ready, error } = usePlaidLink(config);

  if (error && plaidError?.error_code !== 'INVALID_LINK_TOKEN') {
    return (
      <InlineAlert type="warning">
        <MdPadding>
          <Description>Something went wrong. Please try again or message us if the problem persists.</Description>
        </MdPadding>
      </InlineAlert>
    );
  }

  return (
    <Button
      loadingLabel="Loading Bank Data"
      type="secondary"
      onPress={open as () => void}
      loading={!ready}
    >
      Link Bank Account
    </Button>
  );
};
