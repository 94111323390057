// @owners { team: patients-team }
import {
  type OrderBundlingOrigin,
  OrderBundlingOriginMap,
} from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/order_bundling_origin';
import { type OrderBundlingEndpointOrderBundlingOptionsRequest } from '@alto/scriptdash/alto/patient_app/scheduling/v1/order_bundling_endpoint';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { getEditShipmentID } from '~shared/features/checkout/selectors/getCart';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';
import { useSelectorShared } from '~shared/store';

type OrderBundlingOptions = {
  origin: OrderBundlingOrigin;
  address_id: OrderBundlingEndpointOrderBundlingOptionsRequest['address_id'];
};

export const getOrderBundlingCheckoutOrigin = ({
  isEditingOrder,
}: {
  isEditingOrder: boolean;
}): OrderBundlingOrigin => {
  if (isEditingOrder) {
    return OrderBundlingOriginMap.EDIT_ORDER;
  } else {
    return OrderBundlingOriginMap.CHECKOUT;
  }
};

export const useOrderBundlingOptions = ({ address_id, origin }: OrderBundlingOptions) => {
  const { prescriptionIDs: cartPrescriptionIDs } = usePrescriptionsInCart();
  const cart_prescription_ids = cartPrescriptionIDs?.join(',');

  const shipmentID = useSelectorShared(getEditShipmentID);
  const shipment_id = origin === 'edit_order' ? shipmentID : null;

  const { data, isError, isPending, isSuccess } = useQuery({
    ...queries.orderBundling.options({
      address_id,
      cart_prescription_ids,
      origin,
      edit_order_shipment_id: shipment_id,
    }),
    staleTime: staleTimes.oneMinute,
    enabled: !!cart_prescription_ids && !!origin && !!address_id,
  });
  const orderBundlingOptions = data?.data?.bundled_options;
  const cartShipment = orderBundlingOptions?.new_shipment;
  const earliestUpcomingShipment = orderBundlingOptions?.original_shipments[0];

  const bundledPrescriptionIDs: number[] = useMemo(() => {
    if (earliestUpcomingShipment?.prescriptions && cartPrescriptionIDs) {
      return [...cartPrescriptionIDs, ...earliestUpcomingShipment.prescriptions.map((rx) => rx.id)];
    } else {
      return [];
    }
  }, [cartPrescriptionIDs, earliestUpcomingShipment?.prescriptions]);

  return {
    isError,
    isPending,
    isSuccess,
    orderBundlingOptions,
    earliestUpcomingShipment,
    cartShipment,
    bundledPrescriptionIDs,
  };
};
