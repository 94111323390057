export const WEB_NAVIGATION_ACTIONS = ['@@router/LOCATION_CHANGE'];

export const EVENTS = Object.freeze({
  ACTION_SHEET_VIEWED: 'Action Sheet Viewed',
  ADD_CARD_TAPPED: 'Add Card Tapped',
  ADD_INSURANCE_CLICKED: 'Add Insurance Clicked',
  ADD_INSURANCE_VIEWED: 'Add Insurance Viewed',
  ADD_MED_CLICKED: 'Add Med Clicked',
  ADD_NEW_METHOD_TAPPED: 'Add New Method Tapped',
  ADD_TO_CART_TAPPED: 'Add To Cart Tapped',
  APP_ANNOUNCEMENT_VIEWED: 'Announcement Viewed',
  APP_ANNOUNCEMENT_CTA_CLICKED: 'Announcement CTA Clicked',
  APP_ANNOUNCEMENT_DISMISSED: 'Announcement Dismissed',
  APP_PROMO_CTA_CLICKED: 'Promo CTA Clicked',
  APP_PROMO_VIEWED: 'Promo Viewed',
  APP_PROMO_DISMISSED: 'Promo Dismissed',
  ASSISTANT_COMPONENT_PRESSED: 'Assistant Component Pressed',
  ASSISTANT_COMPONENT_VIEWED: 'Assistant Component Viewed',
  ASSISTANT_MESSAGE_SENT: 'Assistant Message Sent',
  ASSISTANT_READ_ONLY_THREAD: 'Assistant Read Only Wundercom Thread',
  ASSISTANT_SELF_SERVICE_COMPLETED: 'Assistant Self Service Completed',
  ASSISTANT_SUPPORT_CASE_CREATED: 'Assistant Support Case Created',
  APP_OPENED: 'App Opened',
  AUTH0_LOGIN_NO_EMAIL: 'Auth0 Login No Email',
  AUTH0_LOGIN_NO_TOKEN: 'Auth0 Login No Token',
  AUTO_REFILL_INFO_VIEWED: 'Auto Refill Info Viewed',
  AUTO_REFILL_MANAGEMENT_CENTER_VIEWED: 'Auto Refill Management Center Viewed',
  AUTO_REFILL_MANAGEMENT_CENTER_ROW_VIEWED: 'Auto Refill Management Center Row Viewed',
  AUTO_REFILL_UI_STATUS_CHANGED: 'Auto Refill UI Status Changed',
  BRANCH_DEVICE_MATCHED: 'Branch Device Matched',
  BIOMETRICS_PROMPT_VIEWED: 'Biometric Prompt Viewed',
  BIOMETRICS_LOGIN_ACTIVATED: 'Biometric Login Activated',
  BIOMETRICS_REAUTH_VIEWED: 'Biometric Reauthorization Viewed',
  BIOMETRICS_REAUTH_SUCCESS: 'Biometric Reauthorization Success',
  CALL_US_CLICKED: 'Call Us Clicked',
  CANCEL_DELIVERY_PAGE_VIEWED: 'Cancel Delivery Page Viewed',
  CAROUSEL_VIEWED: 'Carousel Viewed',
  CART__UNSCHEDULABLE_PRESCRIPTION_REMOVED: 'Cart - Unschedulable Prescription Removed',
  CART__CHECKOUT_TAPPED: 'Cart - Checkout Tapped',
  CART_CHECKOUT_TAPPED: 'Cart Checkout Tapped',
  CART__ITEM_ADDED: 'Cart - Item Added',
  CART__ITEM_REMOVED: 'Cart - Item Removed',
  CART_VIEWED: 'Cart Viewed',
  CART_VIEWED_WITH_NEXT_AVAILABLE_DATE: 'Cart Viewed with Next Available Date',
  CASH_PAY_ONLY_SET: 'Cash Pay Only Set',
  CHECKOUT__ADDRESS_SELECTED: 'Checkout - Address Selected',
  CHECKOUT__DATE_SELECTED: 'Checkout - Date Selected',
  CHECKOUT__HOME_TO_SIGN_DECISION: 'Checkout - Home To Sign Decision',
  CHECKOUT__PAYMENT_METHOD_SELECTED: 'Checkout - Payment Method Selected',
  CHECKOUT__PICKUP_SELECTED: 'Checkout - Pickup Selected',
  CHECKOUT__DELIVERY_TOGGLED: 'Checkout - Delivery Toggled',
  CHECKOUT__PICKUP_TOGGLED: 'Checkout - Pickup Toggled',
  CHECKOUT__PLACE_ORDER_FAILED: 'Checkout - Place Order Failed',
  CHECKOUT__PLACE_ORDER_SUCCEEDED: 'Checkout - Place Order Succeeded',
  CHECKOUT__PLACE_ORDER_TAPPED: 'Checkout - Place Order Tapped',
  CHECKOUT__SPLIT_ORDER_MODAL_VIEWED: 'Checkout - Split Order Modal Viewed',
  CHECKOUT__WINDOW_SELECTED: 'Checkout - Window Selected',
  CHECKOUT_VALIDATION_ERROR: 'Checkout - Validation Error',
  CHECKOUT_VIEWED: 'Checkout Viewed',
  CHECKOUT_VIEWED_WITH_FULFILLMENT_OPTIONS: 'Checkout Viewed With Fulfillment Options',
  CHECKOUT_VIEWED_WITH_FULFILLMENT_WINDOW_OPTIONS: 'Checkout Viewed with Fulfillment Window Options',
  CONTACT_US_CLICKED: 'Contact Us Clicked',
  COMMS_PREFERENCE_VIEWED: 'Comms Preference Page Viewed',
  COURIER_TIPS__AMOUNT_SELECTED: 'Courier Tips - Amount Selected',
  COURIER_TIPS__CUSTOM_AMOUNT_TAPPED: 'Courier Tips - Custom Amount Tapped',
  COURIER_TIPS__EDIT_DELIVERY_TIP_SUBMITTED: 'Courier Tips - Edit-Delivery Tip Submitted',
  COURIER_TIPS__EDIT_DELIVERY_TIP_VIEWED: 'Courier Tips - Edit-Delivery Tip Viewed',
  COURIER_TIPS__EDIT_TIP_DISMISSED: 'Courier Tips - Edit Tip Dismissed',
  COURIER_TIPS__EDIT_TIP_SUBMITTED: 'Courier Tips - Edit Tip Submitted',
  COURIER_TIPS__EDIT_TIP_TAPPED: 'Courier Tips - Edit Tip Tapped',
  COURIER_TIPS__EDIT_TIP_VIEWED: 'Courier Tips - Edit Tip Viewed',
  COURIER_TIPS__POST_DELIVERY_TIP_DISMISSED: 'Courier Tips - Post-Delivery Tip Dismissed',
  COURIER_TIPS__POST_DELIVERY_TIP_SUBMITTED: 'Courier Tips - Post-Delivery Tip Submitted',
  COURIER_TIPS__POST_DELIVERY_TIP_VIEWED: 'Courier Tips - Post-Delivery Tip Viewed',
  COURIER_TIPS__PRE_DELIVERY_TIP_SUBMITTED: 'Courier Tips - Pre-Delivery Tip Submitted',
  COURIER_TIPS__PRE_DELIVERY_TIP_VIEWED: 'Courier Tips - Pre-Delivery Tip Viewed',
  COURIER_TIPS__TIP_UPDATED: 'Courier Tips - Tip Updated',
  CREATE_PAYMENT_METHOD_FAILED: 'Create Payment Method Failed',
  CSAT_FORM__VIEWED: 'CSAT Form - Viewed',
  CSAT_FORM__SUBMITTED: 'CSAT Form - Submitted',
  CRITICAL_ACTION_FAILURE: 'Critical Action Failure',
  DEEP_LINKING_NOT_SET_UP: 'Deep Linking Not Set Up',
  DEEP_LINKING_LINK_CLICKED: 'Deep Linking Link Clicked',
  DEEP_LINK_ROUTE_NOT_FOUND: 'Deep Link Route Not Found',
  DELIVERY_DETAILS_VIEWED: 'Delivery Details Viewed',
  DELIVERY_DETAILS_CANCEL_BUTTON_CLICKED: 'Delivery Details Cancel Button Clicked',
  DELIVERY_DETAILS_DOWNLOAD_BUTTON_CLICKED: 'Delivery Details Download Button Clicked',
  DELIVERY_DETAILS_EDIT_BUTTON_CLICKED: 'Delivery Details Edit Button Clicked',
  DELIVERY_DETIALS_SHORT_FILL_INFO_VIEWED: 'Delivery Details - Short Fill Info Viewed',
  DELIVERY_DETAILS_SNOOZE_BUTTON_CLICKED: 'Delivery Details Snooze Button Clicked',
  DELIVERY_STATUS_TRACKER_VIEWED: 'Track Status Tracker Viewed',
  DELIVERIES_LIST_VIEWED: 'Deliveries List Viewed',
  DOSAGE_CHECK_VIEWED: 'Dosage Check Viewed',
  DOSAGE_CHANGE_INFO_VIEWED: 'Dosage Change Info Viewed',
  DOSAGE_CHANGE_INFO_CONFIRM_TAPPED: 'Dosage Change Info Confirm Tapped',
  DOSAGE_CHANGE_INFO_CANCEL_TAPPED: 'Dosage Change Info Cancel Tapped',
  DOWNLOAD_FSA_ANNUAL_RECEIPT_CLICKED: 'Download FSA Annual Receipt Clicked',
  EARLIEST_DATE_COMPOUND_MODAL_CONTINUE_CLICKED: 'Earliest Date Compound Modal Continue Clicked',
  EARLIEST_DATE_COMPOUND_MODAL_EDIT_CLICKED: 'Earliest Date Compound Modal Edit Clicked',
  EARLIEST_DATE_COMPOUND_MODAL_VIEWED: 'Earliest Date Compound Modal Viewed',
  ESSENTIALS__ALL_OTCS_VIEWED: 'Add On OTCs - All OTCs Viewed',
  ESSENTIALS__CAROUSEL_SCROLLED: 'Add On OTCs - Carousel Scrolled',
  ESSENTIALS__CATEGORY_DETAILS_VIEWED: 'Add On OTCs - Category Details Viewed',
  ESSENTIALS__EMPTY_SEARCH_RESULTS_VIEWED: 'Add On OTCs - Empty Search Results Viewed',
  ESSENTIALS__GRID_RENDERED: 'Add On OTCs - Grid Rendered',
  ESSENTIALS__INTERSTITIAL_VIEWED: 'Add On OTCs - Interstitial Viewed',
  ESSENTIALS__INTRODUCING_ESSENTIALS_CARD_TAPPED: 'Essentials - Introducing Essentials Card Tapped',
  ESSENTIALS__INTRODUCING_ESSENTIALS_CARD_VIEWED: 'Essentials - Introducing Essentials Card Viewed',
  ESSENTIALS__NO_PAYMENT_METHOD: 'Add On OTCs - No Payment Method',
  ESSENTIALS__ORDER_SUCCESS_WITH_ESSENTIALS: 'Order Success With Essentials Viewed',
  ESSENTIALS__OTC_ADDED_TO_CART: 'Add On OTCs - OTC Added To Cart',
  ESSENTIALS__OTC_ADD_TO_CART_TAPPED: 'Add On OTCs - OTC Add To Cart Tapped',
  ESSENTIALS__OTC_ADDED_TO_ORDER: 'Add On OTCs - OTC Added To Order',
  ESSENTIALS__OTC_ADD_TO_ORDER_TAPPED: 'Add On OTCs - OTC Add To Order Tapped',
  ESSENTIALS__OTC_DETAILS_CAROUSEL_IMAGE_VIEWED: 'Add On OTCs - OTC Details Carousel Image Viewed',
  ESSENTIALS__OTC_DETAILS_CAROUSEL_IMAGE_ZOOM_IN: 'Add On OTCs - OTC Details Carousel Image Zoom In',
  ESSENTIALS__OTC_DETAILS_CAROUSEL_IMAGE_PAN: 'Add On OTCs - OTC Details Carousel Image Pan',
  ESSENTIALS__OTC_DETAILS_VIEWED: 'Add On OTCs - OTC Details Viewed',
  ESSENTIALS__OTC_DETAILS_DISMISSED: 'Add On OTCs - OTC Details Dismissed',
  ESSENTIALS__OTC_QUANTITY_CHANGED: 'Add On OTCs - OTC Quantity Changed',
  ESSENTIALS__OTC_REMOVED_FROM_CART: 'Add On OTCs - OTC Removed From Cart',
  ESSENTIALS__OTC_REMOVED_FROM_ORDER: 'Add On OTCs - OTC Removed From Order',
  ESSENTIALS__PRODUCT_DETAILS_VIEWED: 'Essentials Product Details Viewed',
  ESSENTIALS__SEARCH_RESULTS_VIEWED: 'Add On OTCs - Search Results Viewed',
  ESSENTIALS__SEE_ALL_OTCS_TAPPED: 'Add On OTCs - See All OTCs Tapped',
  ESSENTIALS__SINGLE_CAROUSEL_VIEWED: 'Add On OTCs - Single Carousel Viewed',
  ESSENTIALS__SHOP_ESSENTIALS_CARD_TAPPED: 'Essentials - Shop Essentials Card Tapped',
  ESSENTIALS__STORE_VIEWED: 'Essentials Store Viewed',
  ESSENTIALS__STORE_CATEGORY_VIEWED: 'Essentials Store Category Viewed',
  ESSENTIALS__STORE_NO_ADDRESS_VIEWED: 'Essentials Store No Address Viewed',
  ESSENTIALS__UPDATE_ACTION_SHEET_VIEWED: 'Essentials Update Action Sheet Viewed',
  ESSENTIALS__FEATURED_ESSENTIALS_VIEWED: 'Essentials Featured Essentials Viewed',
  ESSENTIALS__RELATED_ESSENTIALS_VIEWED: 'Essentials Related Essentials Viewed',
  ESSENTIALS__SEARCH_FEEDBACK_FORM_VIEWED: 'Essentials Search Feedback Form Viewed',
  ESSENTIALS__SEARCH_FEEDBACK_FORM_SUBMITTED: 'Essentials Search Feedback Form Submitted',
  ESSENTIALS_INTERSTITIAL__COMPLETE_ORDER_TAPPED: 'Add On OTCs Interstitial - Complete Order Tapped',
  ESSENTIALS_INTERSTITIAL__SKIP_TAPPED: 'Add On OTCs Interstitial - Skip Tapped',
  ESSENTIALS_TA_TARGETED_UPSELL_VIEWED: 'Add On OTCs - TA Targeted OTC Upsell Viewed',
  ESSENTIALS_TA_TARGETED_UPSELL_TAPPED: 'Add On OTCs - TA Targeted OTC Upsell Tapped',
  EXPERIMENT_EXPOSURE: 'Experiment Exposure',
  EXPERIMENT_FEATURE_DECISION: 'Experiment Feature Decision',
  FAMILY_ACCOUNTS_ADD_FAMILY_MEMBER_FORM_VIEWED: 'Family Accounts - Add Family Member Form Viewed',
  FAMILY_ACCOUNTS_ADD_FAMILY_MEMBER_REQUEST_SENT_VIEWED: 'Family Accounts - Add Family Member Request Sent Viewed',
  FAMILY_ACCOUNTS_FAMILY_MEMBER_FOUND: 'Family Accounts - Family Member Found',
  FAMILY_ACCOUNTS_FAMILY_MEMBER_ADDED_VIEWED: 'Family Accounts - Family Member Added Viewed',
  FETCH_MORE_SUPPORT_CASES: 'Fetch More Support Cases',
  FORGOT_PASSWORD_VIEWED: 'Forgot Password Viewed',
  GET_IT_SOONER_ACTION_SHEET_VIEWED: 'Get It Sooner Action Sheet Viewed',
  GET_IT_SOONER_ACTION_SHEET_OPTION_SELECTED: 'Get It Sooner Action Sheet Option Selected',
  HOMESCREEN_VIEWED: 'Homescreen Viewed',
  HOMESCREEN__RENEWALS_VIEWED: 'Homescreen — Renewals Viewed',
  HOMESCREEN__NAV_CARD_TAPPED: 'Homescreen Nav Card Tapped',
  INSTRUCTIONAL_VIDEO_PLAYED: 'Instructional Video Played',
  INVALID_PUSH_NOTIFICATION_URL: 'Invalid Push Notification URL',
  INSURANCE_ADDED: 'Insurance Added',
  INSURANCE_REMOVED: 'Insurance Removed',
  LOGIN_CLICKED: 'Login Clicked',
  LOGIN_VIEWED: 'Login Viewed',
  LOGOUT_CLICKED: 'Logout Clicked',
  LOGOUT_STARTED: 'Logout Started',
  MANUAL_COUPONS_APPLY_COUPON_CLICKED: 'Manual Coupons - Apply Coupon Clicked',
  MANUAL_COUPONS_COUPON_SEEN_BY_PATIENT: 'Manual Coupons - Coupon Seen By Patient',
  MANUAL_COUPONS_COUPON_SUBMITTED: 'Manual Coupons - Coupon Submitted',
  MANUAL_COUPONS_SUPPORT_REQUESTED: 'Manual Coupons - Support Requested',
  MED_DETAILS__ARCHIVE_CONFIRMATION_VIEWED: 'Med List Detail Archive Confirmation Viewed',
  MED_DETAILS__ALTERNATIVE_MEDICATION_SHOWN: 'Med List Detail Alternative Medication Shown',
  MED_DETAILS__COMPOUNDED_MEDICATION_SHOWN: 'Med List Detail Compounded Medication Shown',
  MED_DETAILS__CONTROLLED_MEDICATION_SHOWN: 'Med List Detail Controlled Medication Shown',
  MED_DETAILS__HOW_TO_INJECT_SHOWN: 'Med List Detail How To Inject Shown',
  MED_DETAILS__MEDICATION_MONOGRAPH_SHOWN: 'Med List Detail Medication Monograph Shown',
  MED_DETAILS__REFRIGERATION_REQUIRED_SHOWN: 'Med List Detail Refrigeration Required Shown',
  MED_DETAILS__LEARN_MORE_VIEWED: 'Med List Detail Learn More Viewed',
  MED_DETAILS__OTHER_PRESCRIPTIONS_SHOWN: 'Med List Detail Other Prescriptions Shown',
  MED_DETAILS__OTHER_PRESCRIPTIONS_VIEWED: 'Med List Detail Other Prescriptions Viewed',
  MED_DETAILS__PRESCRIPTION_CARD_VIEWED: 'Med List Detail Prescription Card Viewed',
  MED_DETAILS__UNARCHIVE_CONFIRMATION_VIEWED: 'Med List Detail Unarchive Confirmation Viewed',
  MED_DETAILS__UPCOMING_DELIVERY_VIEWED: 'Med List Detail Upcoming Delivery Viewed',
  MED_DETAILS__VIEWED: 'Med List Details Viewed',
  MED_DETAILS__INFOBOX_VIEWED: 'Med Details Infobox Viewed',
  MED_INFO_SUBMITTED: 'Med Info Submitted',
  MED_LIST_VIEWED: 'Med List Viewed',
  MED_LIST__FILTER_FAMILY_MEMBERS_VIEWED: 'Med List Family Members Action Sheet Viewed',
  MED_LIST__FILTER_MEDICATIONS_VIEWED: 'Med List Filter Medications Action Sheet Viewed',
  MED_LIST__MEDICATIONS_SEARCHED: 'Med List Medications Searched',
  MED_LIST__ROW_VIEWED: 'Med List Row Viewed',
  MED_LIST__SORT_MEDICATIONS_VIEWED: 'Med List Sort Medications Action Sheet Viewed',
  MED_LIST__TAB_PRESSED: 'Med List Tab Pressed',
  MED_SYNC_BUNDLE_MEDICATIONS_INFO_VIEWED: 'MedSync Bundle Medications Info Viewed',
  MED_SYNC_SELECT_MEDICATIONS_VIEWED: 'MedSync Select Medications Viewed',
  MED_SYNC_SELECT_MEDICATIONS_REQUEST_BUNDLE_CLICKED: 'MedSync Select Medications Request Bundle Clicked',
  MED_SYNC_SELECT_MEDICATIONS_DISMISSED: 'MedSync Select Medications Dismissed',
  MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_CLICKED: 'MedSync Bundle Medications Request Clicked',
  MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_DISMISS_CLICKED: 'MedSync Bundle Medications Request Dismiss Clicked',
  MED_SYNC_BUNDLE_MEDICATIONS_REQUEST_RECEIVED_VIEWED: 'MedSync Bundle Medications Request Received Viewed',
  MED_SYNC_HOMESCREEN_ELIGIBLE_CARD_CLICKED: 'MedSync Homescreen Eligible Card Button Clicked',
  MED_SYNC_HOMESCREEN_INTERESTED_CARD_CLICKED: 'MedSync Homescreen Interested Card Button Clicked',
  MED_SYNC_HOMESCREEN_CONFIRM_CARD_CLICKED: 'MedSync Homescreen Confirm Card Button Clicked',
  MED_SYNC_PLAN_CONFIRMED_VIEWED: 'MedSync Plan Confirmed Viewed',
  MED_SYNC_PLAN_REVIEW_CONFIRM_CLICKED: 'MedSync Plan Review Confirm Clicked',
  MED_SYNC_PLAN_REVIEW_DISMISS_CLICKED: 'MedSync Plan Review Dismiss Clicked',
  MED_SYNC_PLAN_REVIEW_GO_BACK_CLICKED: 'MedSync Plan Review Go Back Clicked',
  MED_SYNC_PLAN_REVIEW_VIEWED: 'MedSync Plan Review Viewed',
  MEDICAL_CONDITIONS_PROMPTED: 'Medical Conditions Prompted',
  MEDICAL_CONDITIONS_SAVED: 'Medical Conditions Saved',
  MEDICAL_CONDITIONS_DISMISSED: 'Medical Conditions Dismissed',
  MEDICATION_MANAGEMENT_OVERVIEW_VIEWED: 'Medication Management Overview Viewed',
  PROGYNY_INTRO_2_VIEWED: 'Progyny Intro 2 Viewed',
  PROGYNY_INTRO_3_VIEWED: 'Progyny Intro 3 Viewed',
  MEDICATION_ON_HAND_VIEWED: 'Medication On Hand Viewed',
  MEDICATION_START_DATES_VIEWED: 'Medication Start Dates Viewed',
  MEDICATION_QUANTITIES_VIEWED: 'Medication Quantities Viewed',
  MESSAGES_VIEWED: 'Messages Viewed',
  MODAL_VIEWED: 'Modal Viewed',
  MOUNJARO_DOSAGE_SELECTION_VIEWED: 'Mounjaro Dosage Selection Viewed',
  MULTIPLE_PRICE_OPTIONS_SHOWN: 'Multiple Price Options Shown',
  NEW_YORK_FERTILITY_NEEDLE_PROMPT_VIEWED: 'New York Fertility Needle Prompt Viewed',
  NEW_YORK_FERTILITY_NEEDLE_PROMPT_COMPLETED: 'New York Fertility Needle Prompt Completed',
  NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED: 'Next Available Date Action Sheet Viewed',
  NEW_YEAR_INSURANCE_TASK_VIEWED: 'New Year Insurance Task Viewed',
  NOTIFICATION_CENTER_VIEWED: 'Notification Center Viewed',
  NOTIFICATION_VIEWED: 'Notification Viewed',
  NUDGE_CARD_VIEWED: 'Nudge Card Viewed',
  NUDGE_CARD_CTA_TAPPED: 'Nudge Card CTA Tapped',
  ONBOARDING__BACK_BUTTON_CLICKED: 'Onboarding Back Button Clicked',
  ONBOARDING__CLAIM_ACCOUNT_VIEWED: 'Claim Account Viewed',
  // legacy
  ONBOARDING__COMPLETED: 'Onboarding Completed',
  ONBOARDING__CONFIRMATION_VIEWED: 'Onboarding Confirmation Viewed',
  ONBOARDING__CREATE_ACCOUNT_VIEWED: 'Create Account Viewed',
  // legacy
  ONBOARDING__CREATE_LOGIN_VIEWED: 'Create Login Viewed',
  // redesign
  ONBOARDING__FAQ_CLICKED: 'Onboarding FAQ Clicked',
  ONBOARDING__FORK_VIEWED: 'Onboarding Fork Viewed',
  // legacy?
  ONBOARDING__HEALTH_PROFILE_COMPLETED: 'Health Profile Completed',
  // legacy
  ONBOARDING__HEALTH_PROFILE_VIEWED: 'Health Profile Viewed',
  // legacy
  ONBOARDING__HELP_VIEWED: 'Onboarding Help Viewed',
  ONBOARDING__INSURANCE_ANIMATION_VIEWED: 'Insurance Animation Viewed',
  ONBOARDING__INSURANCE_FOUND_VIEWED: 'Insurance Found Viewed',
  // redesign
  ONBOARDING__INSURANCE_INPUT_VIEWED: 'Insurance Input Viewed',
  // redesign
  ONBOARDING__INSURANCE_INPUT_MANUAL_VIEWED: 'Insurance Input Manual Viewed',
  // redesign
  ONBOARDING__INSURANCE_INPUT_PHOTO_VIEWED: 'Insurance Input Photo Viewed',
  // redesign
  ONBOARDING__INSURANCE_NONE_CLICKED: 'Insurance None Clicked',
  ONBOARDING__INSURANCE_REMINDER_CLICKED: 'Insurance Reminder Clicked',
  ONBOARDING__INSURANCE_STEP_COMPLETED: 'Onboarding - Insurance Step Completed',
  // legacy
  ONBOARDING__INSURANCE_STEP_VIEWED: 'Onboarding - Insurance Step Viewed',
  // legacy
  ONBOARDING__MED_CONFIRMATION_VIEWED: 'Med Confirmation Viewed',
  // redesign
  ONBOARDING__MEDICAL_INFO_VIEWED: 'Medical Info Viewed',
  // redesign
  ONBOARDING__PHI_AUTHORIZATION_VIEWED: 'Onboarding PHI Form Viewed',
  ONBOARDING__PHI_AUTHORIZATION_COMPLETED: 'Onboarding PHI Form Completed',
  ONBOARDING__TRANSFER_FORM_VIEWED: 'Onboarding Transfer Form Viewed',
  // legacy
  ONBOARDING__TRANSFER_INFO_VIEWED: 'Onboarding Transfer Info Viewed',
  ONBOARDING__TRANSFER_SKIPPED: 'Onboarding - Transfer Skipped',
  ONBOARDING__TRANSFER_SUCCESS_VIEWED: 'Onboarding Transfer Success Viewed',
  // legacy
  ONBOARDING__VERIFY_ACCOUNT_VIEWED: 'Verify Account Viewed',
  // redesign
  ONBOARDING__WEB_LANDING_PAGE_VIEWED: 'Web Landing Page Viewed',
  // legacy
  ONBOARDING__WELCOME_VIEWED: 'Welcome Viewed',
  ONBOARDING_OOZ_ZIP_CODE: 'Onboarding Out-Of-Zone Zip Code Screen Viewed',
  ONBOARDING_FERT_DELIVERY_SIGN_UP_CLICKED: 'Onboarding Fertility Delivery Sign Up Clicked',
  ON_DEMAND_ORDER_ELIGIBLE_BUT_NO_WINDOWS: 'On Demand Order Eligible But No Windows',
  ON_DEMAND_ORDER_OPTION_VIEWED: 'On Demand Order Option Viewed',
  ON_DEMAND_ORDER_PLACED: 'On Demand Order Placed',
  ORDER_BUNDLING_ACTION_SHEET_VIEWED: 'Order Bundling Action Sheet Viewed',
  ORDER_DATE_PICKER_VIEWED: 'Order Date Picker Viewed',
  // legacy
  ORDER_SUCCESS__TRANSFER_CLICKED: 'Order Success - Transfer Meds CTA Clicked',
  ORDER_SUCCESS__TRANSFER_VIEWED: 'Order Success - Transfer Meds CTA Viewed',
  ORDER_SUCCESS__CSAT_SUBMITTED: 'Order Success CSAT Submitted',
  ORDER_SUCCESS_VIEWED: 'Order Success Viewed',
  ORDER_TYPE_SCREEN_VIEWED: 'Order Type Screen Viewed',
  ORGANIC_ONBOARDING_LANDING_PAGE_VIEWED: 'Organic Onboarding Landing Page Viewed',
  PAYMENT_BREAKDOWN_VIEWED: 'Payment Breakdown Viewed',
  PAYMENT_BREAKDOWN_ITEM_VIEWED: 'Payment Breakdown Item Viewed',
  PAYMENT_METHOD_CREATED: 'Payment Method Created',
  PAYMENT_METHOD_PICKER__DONE_TAPPED: 'Payment Method Picker - Done Tapped',
  PAYMENT_METHOD_RENDERED: 'Payment Method Rendered',
  PHARMACY_INFO__LOCATION_SELECTED: 'Pharmacy Info - Location Selected',
  PHARMACY_INFO_VIEWED: 'Pharmacy Info Viewed',
  PHI_PREFERENCES_INTERSTITIAL_DISMISSED: 'PHI Preferences Interstitial Dismissed',
  PHI_PREFERENCES_INTERSTITIAL_SUBMITTED: 'PHI Preferences Interstitial Submitted',
  PHI_PREFERENCES_INTERSTITIAL_VIEWED: 'PHI Preferences Interstitial Viewed',
  PLAID_ERROR: 'Plaid Error',
  PLAID_EXITED: 'Plaid Exited',
  PLAID_FINISHED: 'Plaid Finished',
  PLAID_STARTED: 'Plaid Started',
  PRESCRIPTION_TRACKER_MODAL_VIEWED: 'Prescription Tracker Modal Viewed',
  PRICE_INCREASE_ALERT_DISMISSED: 'Price Increase Alert Dismissed',
  PRICE_INCREASE_ALERT_VIEWED: 'Price Increase Alert Viewed',
  PRICE_INFO_VIEWED: 'Price Info Viewed',
  PRICE_SELECTED: 'Price Selected',
  PRICE_BREAKDOWN_VIEWED: 'Pricing Breakdown Viewed',
  PROFILE__INSURANCE_INFO_VIEWED: 'Profile - Insurance Info Viewed',
  PROFILE_VIEWED: 'Profile Viewed',
  PROGYNY_UPSELL__DOWNLOAD_APP_TAPPED: 'Progyny Upsell - Download App Tapped',
  PROGYNY_FERTILITY_CYCLE_FORM_PROMPT_VIEWED: 'Progyny Fertility Cycle Form Prompt Viewed',
  PROGYNY_FERTILITY_CYCLE_FORM_PROMPT_PRESSED: 'Progyny Fertility Cycle Form Prompt Pressed',
  PROVIDER_SIGNIN_LINK_TAPPED: 'Provider Sign-in Link Tapped',
  PUSH_NOTIFICATION_OPENED: 'Push Notification Opened',
  PUSH_NOTIFICATION_OPENED_IN_BACKGROUND: 'Push Notification Opened In Background',
  PUSH_NOTIFICATION_OPENED_IN_FOREGROUND: 'Push Notification Opened In Foreground',
  PUSH_NOTIFICATION_OPENED_IN_INACTIVE: 'Push Notification Opened In Inactive',
  PUSH_NOTIFICATION_REGISTER_FETCH_TOKEN: 'Push Notification Fetch Device Token',
  PUSH_NOTIFICATION_RECEIVED: 'Push Notification Received',
  PUSH_NOTIFICATION_RECEIVED_IN_BACKGROUND: 'Push Notification Received In Background',
  PUSH_NOTIFICATION_UPSELL_TAPPED: 'Push Notification Upsell Tapped',
  PUSH_NOTIFICATION_UPSELL_VIEWED: 'Push Notification Upsell Viewed',
  PUSH_NOTIFICATION_PERMISSIONS_SHOWN: 'Push Notification Permissions Shown',
  PUSH_NOTIFICATIONS_REQUESTED_APP_LAUNCH: 'Push Notifications Requested - App Launch',
  PUSH_NOTIFICATIONS_DISABLED_COMMS_CHANGE: 'Push Notifications Disabled - Preferred Comms Changed',
  PUSH_NOTIFICATIONS_DELIVERY_SCHEDULED: 'Push Notification Permissions - Delivery Scheduled',
  QUANTITY_CHANGE_INITIATED: 'Quantity Change Initiated',
  QUANTITY_CHANGE_CONFIRMED: 'Quantity Change Confirmed',
  RATE_SHIPMENT__EXPERIENCE_VIEWED: 'Rate Shipment - Experience Viewed',
  RATE_SHIPMENT__RATING_CLICKED: 'Rate Shipment - Rating Clicked',
  RATE_SHIPMENT__DISMISS_CLICKED: 'Rate Shipment - Dismiss Clicked',
  RATE_SHIPMENT__SUBMIT_CLICKED: 'Rate Shipment - Submit Clicked',
  RATINGS__FEEDBACK_PROMPT_VIEWED: 'Ratings - Feedback Prompt Viewed',
  RATINGS__GIVE_FEEDBACK_CLICKED: 'Ratings - Give Feedback Clicked',
  RATINGS__RATE_ALTO_CLICKED: 'Ratings - Rate Alto Clicked',
  RATINGS__RATING_PROMPT_VIEWED: 'Ratings - Rating Prompt Viewed',
  RATINGS__SOFT_PROMPT_VIEWED: 'Ratings - Soft Prompt Viewed',
  REFERRAL_LINK_SHARED: 'Referral Link Shared',
  REFILL_CROSS_SELL_VIEWED: 'Refill Cross Sell Viewed',
  REFILL_CROSS_SELL_ITEM_ADDED: 'Refill Cross Sell - Item Added',
  REFILL_CROSS_SELL_ITEM_REMOVED: 'Refill Cross Sell - Item Removed',
  REFILL_CROSS_SELL_ITEM_VIEWED: 'Refill Cross Sell - Item Viewed',
  REFILL_CROSS_SELL_ITEMS_ADDED: 'Refill Cross Sell - Items Added',
  RENEWAL_STATUS_TAG_PRESSED: 'Renewal Status Tag Pressed',
  RENEWAL_STATUS_TAG_VIEWED: 'Renewal Status Tag Viewed',
  RENEWAL_REQUEST__CONFIRMATION_VIEWED: 'Renewal Request - Confirmation Viewed',
  RENEWAL_REQUEST__SUBMIT_CLICKED: 'Renewal Request - Submit Clicked',
  RESET_PASSWORD_CLICKED: 'Reset Password Clicked',
  SAME_DAY_DELIVERY_FEE_SELECTED: 'Same-Day Delivery Fee Selected',
  DELIVERY_FEE_TOOLTIP_SHOWN: 'Delivery Fee Tooltip Shown',
  SELECT_DATE_VIEWED: 'Select Date Viewed',
  SELECT_OTHER_DATE_TAPPED: 'Select Other Date Tapped',
  SEND_PRESCRIPTION_OPTIONS_VIEWED: 'Send Prescription Options Viewed',
  SHIPMENT_CANCELED: 'Shipment Canceled',
  SHIPMENT_NEXT_AVAILABLE_DATE_ACTION_SHEET_VIEWED: 'Shipment Next Available Date Action Sheet Viewed',
  SIGNUP_CREATE_ACCOUNT_VIEWED: 'Signup Create Account Viewed',
  SNOOZE_SHIPMENT_VIEWED: 'Snooze Shipment Viewed',
  SNOOZE_SHIPMENT_OPTION_SELECTED: 'Snooze Shipment - Option Selected',
  SUPPORT_VIEWED: 'Support Viewed',
  SPLIT_PAYMENTS_TOGGLE_CLICKED: 'Split Payment Toggle Clicked',
  SPLIT_PAYMENTS_ADD_SECOND_PAYMENT: 'Add Second Payment Method',
  SPLIT_PAYMENTS_ADD_NEW_PAYMENT: 'Add New Payment Method',
  TAB_BOTTOM_BAR_BUTTON_PRESSED: 'Tab Bottom Bar Button Pressed',
  TRACK_DELIVERY_BUTTON_PRESSED: 'Track Delivery Button Pressed',
  TRANSFER__TRANSFER_MEDS_CLICKED: 'Transfer My Meds Clicked',
  TRANSFER__PHARMACY_SELECTED: 'Transfer Form - Pharmacy Selected',
  TRANSFER__MEDICATION_SELECTED: 'Transfer Form - Medication Selected',
  TRANSFER__TRANSFER_ALL_TAPPED: 'Transfer Form - Transfer All Tapped',
  TRANSFER_PREFILLED_MEDICATIONS_VIEWED: 'Prefilled Medications Viewed',
  TRANSFER_PREFILLED_MEDICATIONS_CLICKED: 'Prefilled Medications Clicked',
  TRANSFER_PREFILLED_MEDICATIONS_NOT_NEEDED_CLICKED: 'Prefilled Medications Not Needed Clicked',
  TRANSFER_PREFILLED_MEDICATIONS_MULTIPLE_PHARMACIES_CLICKED: 'Prefilled Medications Contact Us Clicked',
  TRANSFER_PRESCRIPTION_VIEWED: 'Transfer Prescription Viewed',
  TRANSFER_PHOTO_VIEWED: 'Transfer Photo Viewed',
  TRANSFER_KAISER_MODAL_VIEWED: 'Kaiser Transfer Modal Viewed',
  TRANSFER_REQUEST_SUBMITTED: 'Transfer Request Submitted',
  TRANSFER_SUCCESS_VIEWED: 'Transfer Success Viewed',
  TRIGGER_DATE_VIEWED: 'Trigger Date Viewed',
  UPDATE_APP_PROMPT_APP_STORE_LINK_TAPPED: 'Update Prompt App Store Link Tapped',
  UPDATE_APP_PROMPT_VIEWED: 'Update Prompt Viewed',
  UPDATE_INSURANCE_CLICKED: 'Update Insurance Clicked',
  UPLOAD_ID_VIEWED: 'Upload ID Viewed',
  USER_FEATURE_FLAGS_FETCHED: 'User Feature Flags Fetched',
  VACATION_SUPPLY_FORM_VIEWED: 'Vacation Supply Form Viewed',
  VACATION_SUPPLY_AUTOBILL_FAIL_FORM_VIEWED: 'Vacation Supply Autobill Fail Form Viewed',
  VIEW_ALL_MEDICATIONS_PRESSED: 'View All Medications Pressed',
  WEB_LANDING_PAGE__GET_STARTED_TAPPED: 'Web Landing Page - Get Started Tapped',
  WEB_LANDING_PAGE__DOWNLOAD_APP_TAPPED: 'Web Landing Page - Download App Tapped',
  ZIP_VERIFICATION_VIEWED: 'Zip Verification Viewed',
  UPSELL_GET_THE_APP_TAPPED: 'Upsell Banner - Get The App Tapped',
  UPSELL_CONTINUE_WITH_BROWSER: 'Upsell Banner - Continue With Browser',
  SHIPMENT_CONFIRMATION_VIEWED: 'Shipment Confirmation Viewed',
  SHIPMENT_CONFIRMATION_EDIT_CLICKED: 'Shipment Confirmation Edit Clicked',
  SHIPMENT_CONFIRMATION_CONFIRMED_CLICKED: 'Shipment Confirmation Confirmed Clicked',
  SHIPMENT_IMAGE_VIEWED: 'Shipment Image Viewed',
  C2_BACKORDER_FAQ_VIEWED: 'C2 Backorder FAQ Viewed',
  C2_BACKORDER_ALERT_VIEWED: 'C2 Backorder Alert Viewed',
  C2_BACKORDER_TAG_VIEWED: 'C2 Backorder Tag Viewed',
  SCHEDULING_BLOCKED_DUE_TO_BACK_ORDER: 'Scheduling Blocked Due to Back Order',
} as const);

export type ANALYTICS_EVENT = (typeof EVENTS)[keyof typeof EVENTS];

export const USER_IDENTIFIERS = {
  REVIEW_SCORE_HASH: 'review_score_hash',
  TEMP_PASSWORD: 'temp_password',
} as const;
