// @owners { team: patients-team }
import { ActionSheetV2, AltoIcon, ListItem } from '@alto/design-system';
import React from 'react';
import { type User } from '~shared/types';

type Props = {
  readonly users: User[];
  readonly onPress: (userID: number, firstName: string | undefined) => void;
};

export const FSAReceiptsFamilyMemberActionSheet = ({ onPress, users }: Props) => {
  return (
    <ActionSheetV2
      title="Choose an account"
      analyticsName="fsa receipt choose family member"
    >
      {users.map((user) => {
        return (
          <ListItem
            key={user.id}
            title={user.full_name}
            LeftContent={<AltoIcon name="user-duo" />}
            RightContent={
              <AltoIcon
                type="grey"
                name="chevronright-small"
              />
            }
            onPress={() => {
              onPress(user.id, user.first_name);
            }}
          />
        );
      })}
    </ActionSheetV2>
  );
};
