// @owners { team: patients-team }
import { useQuery } from '@tanstack/react-query';
import { staleTimes } from '~shared/queries/constants';
import { queries } from '~shared/queries/query-keys';

export const useQueryCompletePageContent = ({ hubReferralID }: { hubReferralID: number }) => {
  const { isFetching, isSuccess, data, isError } = useQuery({
    ...queries.hubReferralInfoRequestConfigs.fetchCompletePageContent({
      params: {
        hub_referral_id: hubReferralID,
      },
    }),
    enabled: !!hubReferralID,
    staleTime: staleTimes.fiveMinutes,
  });

  return {
    isFetching,
    isSuccess,
    isError,
    programName: data?.data?.program_name,
    headerLogoUrl: data?.data?.header_logo_url,
    completeImageUrl: data?.data?.complete_image_url,
    completeHeader: data?.data?.complete_header,
    completeBody: data?.data?.complete_body,
  };
};
