// @owners { team: patients-team }
import { COLORS, SIZES } from '@alto/design-library-tokens';
import { AltoSpinningLoader, Body, LandingPage, MdSpacing, Row } from '@alto/design-system';
import React from 'react';
import { withRouter } from 'react-router';
import { IMG_ALT_TEXTS } from '~shared/constants';
import { Image } from '~web/features/essentials/primitives';
import { useQueryCompletePageContent } from '~web/features/info-request/hooks';

type Props = {
  params: {
    hubReferralID: string;
  };
};

const CompletePageComponent = (props: Props) => {
  const { hubReferralID: hubReferralIDParam } = props.params || {};
  const hubReferralID = Number(hubReferralIDParam || 0);
  const { isFetching, completeHeader, completeBody, completeImageUrl } = useQueryCompletePageContent({
    hubReferralID,
  });

  if (isFetching) {
    return (
      <Row rowCenter>
        <AltoSpinningLoader />
      </Row>
    );
  }

  return (
    <LandingPage
      withoutWebFramingElementHeights
      HeaderBodyCopy={<Body>{completeBody}</Body>}
      backgroundColor={COLORS.BACKGROUND_COLORS.GREY_LIGHTEST}
      title={completeHeader || ''}
    >
      {completeImageUrl ? (
        <>
          <MdSpacing />
          <Row center>
            <Image
              src={completeImageUrl}
              alt={IMG_ALT_TEXTS.infoRequestCompletion}
              width={SIZES.ILLUSTRATION.XL.px}
            />
          </Row>
        </>
      ) : undefined}
    </LandingPage>
  );
};

export const CompletePage = withRouter(CompletePageComponent);
