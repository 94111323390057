// @owners { team: patients-team }
import { Description, InlineAlert, LgPadding, Link, MdPadding, XxsSpacing } from '@alto/design-system';
import { useNavigation } from '@alto/navigation';
import React from 'react';
import { Platform } from 'react-native';

export const FSAIndividualReceiptAlert = () => {
  const { navigate } = useNavigation();

  const goToOrders = () => {
    navigate('RouteAppTabNavigator', { screen: 'RouteTabOrders' });
  };

  const alert = (
    <InlineAlert>
      <Description fontFamily="semibold">Looking for individual receipts?</Description>
      <XxsSpacing />
      <Description>
        Receipts for individual orders are available on the order details page once the order has been completed.
      </Description>
      <XxsSpacing />
      <Link
        onPress={goToOrders}
        textSize="mini"
      >
        View orders
      </Link>
    </InlineAlert>
  );

  if (Platform.OS === 'web') {
    return <LgPadding>{alert}</LgPadding>;
  }

  return <MdPadding>{alert}</MdPadding>;
};
