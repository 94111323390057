// @owners { team: patients-team }
import { type DeliveryMethod } from '@alto/deliver_api/types/delivery_methods/v1/delivery_method';
import { AltoIcon, Column, PresentationListItem, SmSpacing } from '@alto/design-system';
import { Experimentation } from '@alto/experimentation';
import React from 'react';
import { usePrescriptionsInCart } from '~shared/features/cart/hooks/usePrescriptionsInCart';
import { CONFIRM } from '~shared/features/checkout/constants';
import { useCourierAgeRestriction } from '~shared/features/checkout/hooks/useCourierAgeRestriction';
import {
  getIsOrderPickupMethod,
  getOrderAddress,
  getOrderPickupAddress,
  getOrderPickupReadyTime,
} from '~shared/features/checkout/selectors/getOrder';
import { type HomeToSignConfirmation } from '~shared/features/checkout/types';
import {
  type Instructions,
  emptyInstruction,
  leavePackageInstruction,
  photoIDRequiredInstruction,
  presenceRequiredInstruction,
  signatureRequiredInstruction,
} from '~shared/features/delivery-info/constants';
import {
  getDeliveryDateText,
  getDeliveryMethod,
  getFormattedAddress,
} from '~shared/features/delivery-info/helpers/getShipmentWithItemsRowProps';
import { type APIOrderStatus } from '~shared/features/delivery-info/types';
import { formatRelativeDateWithDow, formatWindowTime } from '~shared/helpers/date';
import { isControl, isExpensive } from '~shared/helpers/helper';
import { isMail, isPickup } from '~shared/helpers/order';
import { useSelectorShared } from '~shared/store';
import { type Address, type Prescription } from '~shared/types';
import { LocationRow } from '../../../info-rows';

type Props = {
  readonly date: string | null | undefined;
  readonly deliverAfter: string | null | undefined;
  readonly deliverBefore: string | null | undefined;
  readonly fullBleed?: boolean;
  readonly homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  readonly method: DeliveryMethod | null | undefined;
  readonly showMethod?: boolean;
  readonly showRefrigeratedInfo?: boolean;
  readonly status: APIOrderStatus;
  readonly isAsapDelivery?: boolean;
};

type GetInstructionsProps = {
  address: Address | null | undefined;
  homeToSignConfirmation: HomeToSignConfirmation | null | undefined;
  prescriptions: Prescription[];
  method: DeliveryMethod | null | undefined;
  status: string;
  use3000Threshold: boolean;
  courierAgeRestrictionEnabled: boolean;
  isOrderPickupMethod?: boolean;
};
export const getInstructions = ({
  address,
  homeToSignConfirmation,
  prescriptions,
  method,
  status,
  use3000Threshold,
  courierAgeRestrictionEnabled,
  isOrderPickupMethod,
}: GetInstructionsProps): Instructions | null => {
  if (!address || ['delivered', 'failed'].includes(status)) {
    return emptyInstruction;
  }

  // for pickups, no need to show this instruction since 'Pick up order' is already shown right above
  if (isPickup(method?.mode) || !!isOrderPickupMethod) {
    return null;
  }

  if (prescriptions.some((p) => isControl(p))) {
    // TODO: use method.mode instead to check if it's a courier delivery
    // when removing the courier_age_retriction_clients growthbook flag
    return courierAgeRestrictionEnabled ? photoIDRequiredInstruction : signatureRequiredInstruction;
  }

  if (isExpensive(prescriptions, use3000Threshold) && homeToSignConfirmation === CONFIRM) {
    return signatureRequiredInstruction;
  }

  if (isMail(method?.mode)) {
    return null;
  }

  if (!address.safe_place) {
    return presenceRequiredInstruction;
  }

  return leavePackageInstruction;
};

type DeliveryDateProps = {
  readonly date: string | null | undefined;
  readonly deliverBefore: string | null | undefined;
  readonly deliverAfter: string | null | undefined;
  readonly fullBleed: boolean;
  readonly method: DeliveryMethod | null | undefined;
  readonly isAsapDelivery?: boolean;
};

const DeliveryDate = ({ date, deliverAfter, deliverBefore, fullBleed, method, isAsapDelivery }: DeliveryDateProps) => {
  const orderPickupReadyTime = useSelectorShared(getOrderPickupReadyTime);
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);

  const shouldShowPickupReadyTime = isOrderPickupMethod && !!orderPickupReadyTime;
  // Today, July 15th, ready by 9:05pm (estimated)
  const pickupReadyTime =
    !!orderPickupReadyTime && !!date
      ? `${formatRelativeDateWithDow(date)}, ready by ${formatWindowTime({ windowTime: orderPickupReadyTime, meridiem: 'aaa', forceReturnMinutes: true, useUTC: false })} (estimated)`
      : null;

  if (date) {
    return (
      <PresentationListItem
        LeftContent={<AltoIcon name="calendar" />}
        fullBleed={fullBleed}
        text={
          shouldShowPickupReadyTime
            ? pickupReadyTime
            : getDeliveryDateText({
                deliverAfter,
                deliverBefore,
                date,
                method,
                isAsapDelivery,
              })
        }
      />
    );
  }

  return (
    <PresentationListItem
      LeftContent={<AltoIcon name="calendar" />}
      fullBleed={fullBleed}
      text="--"
    />
  );
};

export const DeliveryLogistics = ({
  date,
  deliverAfter,
  deliverBefore,
  fullBleed = false,
  homeToSignConfirmation,
  method,
  showMethod = true,
  showRefrigeratedInfo = true,
  status,
  isAsapDelivery,
}: Props) => {
  const address = useSelectorShared(getOrderAddress);
  const { prescriptions } = usePrescriptionsInCart();
  const isOrderPickupMethod = useSelectorShared(getIsOrderPickupMethod);
  const pickupAddress = useSelectorShared(getOrderPickupAddress);
  const { value: useNewThreshold } = Experimentation.useFeatureFlag('use_new_expensive_threshold');
  const courierAgeRestrictionEnabled = useCourierAgeRestriction(address?.dispensing_facility_id, method?.mode);
  const deliveryMethod = getDeliveryMethod(method, showMethod, status, isOrderPickupMethod);
  const instructions = getInstructions({
    address,
    homeToSignConfirmation,
    prescriptions,
    method,
    status,
    use3000Threshold: useNewThreshold,
    courierAgeRestrictionEnabled,
    isOrderPickupMethod,
  });
  const requiresRefrigeration = prescriptions.some((p) => p.is_refrigerated);
  const hasAncillaryItems = prescriptions.some((p) => p.has_ancillary_items);
  const deliveryAddress = isOrderPickupMethod ? pickupAddress : getFormattedAddress(address);

  return (
    <Column
      flexShrink={1}
      flexGrow={1}
    >
      <DeliveryDate
        date={date}
        deliverAfter={deliverAfter}
        deliverBefore={deliverBefore}
        fullBleed={fullBleed}
        method={method}
        isAsapDelivery={isAsapDelivery}
      />
      <LocationRow
        address={deliveryAddress}
        isPickup={isOrderPickupMethod}
        showMap={status !== 'delivered'}
      />
      {deliveryMethod.text ? (
        <PresentationListItem
          LeftContent={<AltoIcon name={deliveryMethod.iconName} />}
          fullBleed={fullBleed}
          text={deliveryMethod.text}
        />
      ) : null}
      {instructions?.text ? (
        <PresentationListItem
          LeftContent={<AltoIcon name={instructions.iconName} />}
          fullBleed={fullBleed}
          text={instructions.text}
          type={instructions.type}
          backgroundColor={instructions.backgroundColor}
        />
      ) : null}
      {showRefrigeratedInfo && requiresRefrigeration ? (
        <PresentationListItem
          LeftContent={<AltoIcon name="snowflake" />}
          fullBleed={fullBleed}
          text="Refrigeration required"
        />
      ) : null}
      {hasAncillaryItems ? (
        <PresentationListItem
          LeftContent={<AltoIcon name="syringe" />}
          fullBleed={fullBleed}
          text="Supplies included"
        />
      ) : null}
      <SmSpacing />
    </Column>
  );
};
