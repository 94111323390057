import { type BundledOrder } from '@alto/scriptdash/alto/patient_app/scheduling/order_bundling/types/v1/bundled_order';
import { format, isToday, parse } from 'date-fns';
import { createSelector } from 'reselect';
import getCart, { getEditOrder } from './getCart';
import { getOrdersByIndex } from './getOrdersByIndex';
import { getAddresses } from '~shared/features/addresses/selectors/getAddresses';
import { HOME_TO_SIGN_CONFIRMATION_MAP } from '~shared/features/checkout/constants';
import { deliveryToItemKey } from '~shared/features/checkout/helpers';
import { type Order } from '~shared/features/checkout/types';
import { getShipmentTipForShipmentID } from '~shared/features/courier-tips/selectors';
import { getDeliveries } from '~shared/features/delivery/selectors/getDeliveries';
import { findMatchingPaymentMethod } from '~shared/features/payment-methods/helpers';
import { getPaymentMethods } from '~shared/features/payment-methods/selectors/getPaymentMethods';
import { getShipmentPaymentMethodsWithDetails } from '~shared/features/payments/selectors/getShipmentPaymentMethods';
import { includesAsapDeliveryFee } from '~shared/features/shipment-fees/helpers/includesAsapDeliveryFee';
import getShipments from '~shared/features/shipments/selectors/getShipments';
import { getIsEditingExistingOrder } from '~shared/features/ui/selectors/getCart';
import getLoading from '~shared/features/ui/selectors/getLoading';
import { toUTC } from '~shared/helpers/date';
import { type ShipmentIDParamLong } from '~shared/selectors/selectorParamTypes';
import { type ReduxStateShared } from '~shared/types';

const getShipmentID = (_: ReduxStateShared, props: ShipmentIDParamLong) => props.shipmentID;

const parseWindowTime = (time: string) => {
  return format(toUTC(new Date(time)), 'p');
};

export const getOrder = createSelector(
  [getIsEditingExistingOrder, getEditOrder, getCart],
  (isEditing: boolean, editOrder: Order, cart: Order): Order => {
    if (isEditing) {
      return editOrder;
    }

    return cart;
  },
);

const getOrigin = (_: ReduxStateShared, origin: string) => origin;

export const getBundledOrder = createSelector(
  [getOrder, getOrigin],
  (order: Order, origin: string | null = null): BundledOrder => ({
    origin,
    home_to_sign_confirmation: order.home_to_sign_confirmation,
    include_sharps_container: order.include_sharps_container,
    payment_method_amount: order.payment_method_amount,
    payment_method_id: order.payment_method_id,
    scheduled_with_progyny_wizard: order.scheduled_with_progyny_wizard,
    second_payment_method_id: order.second_payment_method_id,
    second_payment_method_amount: order.second_payment_method_amount,
    needle_prompt_shown: order.needle_prompt_shown,
  }),
);

export const getExistingOrder = createSelector(
  [
    getDeliveries,
    getPaymentMethods,
    getShipments,
    getShipmentPaymentMethodsWithDetails,
    getShipmentID,
    getShipmentTipForShipmentID,
  ],
  (deliveries, paymentMethods, shipments, shipmentPaymentMethods, shipmentID?, shipmentTip?) => {
    const deliveriesInShipment = deliveries.filter(
      (d) => d.shipment_id && String(d.shipment_id) === String(shipmentID),
    );

    let order: Order = {};
    const tipPaymentMethod = findMatchingPaymentMethod(paymentMethods, shipmentTip?.payment_method_id);

    // Use shipment info when available
    if (shipmentID) {
      const shipment = shipments.find((s) => String(s.id) === String(shipmentID));

      if (shipment) {
        order = {
          address_id: shipment.address_id,
          date: shipment.scheduled_date,
          deliver_before: shipment.scheduled_deliver_before && parseWindowTime(shipment.scheduled_deliver_before),
          deliver_after: shipment.scheduled_deliver_after && parseWindowTime(shipment.scheduled_deliver_after),
          home_to_sign_confirmation:
            shipment.home_to_sign_confirmation && HOME_TO_SIGN_CONFIRMATION_MAP[shipment.home_to_sign_confirmation],
          include_sharps_container: shipment.includes_sharps_container,
          is_asap: includesAsapDeliveryFee(shipment.shipment_fees),
          shipment_payment_methods: shipmentPaymentMethods,
        };
      }
    }

    // Fallback to getting the information from one of the deliveries in the
    // shipment. Not ideal, but we currently filter out all shipments in the
    // processing status.
    if (deliveriesInShipment.length > 0) {
      const delivery = deliveriesInShipment[0];
      order = {
        ...order,
        address_id: delivery.address_id,
        date: delivery.date,
        deliver_before: delivery.deliver_before && parseWindowTime(delivery.deliver_before),
        deliver_after: delivery.deliver_after && parseWindowTime(delivery.deliver_after),
        home_to_sign_confirmation:
          delivery.home_to_sign_confirmation && HOME_TO_SIGN_CONFIRMATION_MAP[delivery.home_to_sign_confirmation],
        include_sharps_container: deliveriesInShipment.some((d) => d.includes_sharps_container),
        shipment_payment_methods: shipmentPaymentMethods,
      };
    }

    if (shipmentPaymentMethods?.length > 0) {
      order.payment_method_id = shipmentPaymentMethods[0].payment_method_id;
      order.payment_method_amount = shipmentPaymentMethods[0].amount;
      order.second_payment_method_id = shipmentPaymentMethods[1]?.payment_method_id;
      order.second_payment_method_amount = shipmentPaymentMethods[1]?.amount;
    }

    if (tipPaymentMethod) {
      order.tip_payment_method_id = tipPaymentMethod.id;
    } else {
      order.tip_payment_method_id = order.payment_method_id;
    }

    if (shipmentTip?.amount) {
      order.tip_amount = shipmentTip?.amount;
    }

    order.items = deliveriesInShipment.map((delivery) => deliveryToItemKey(delivery.id));
    return order;
  },
);

export const getExistingOrderPaymentID = createSelector([getExistingOrder], (order) => order.payment_method_id);

export const getOrderAddress = createSelector([getOrder, getAddresses], (order, addresses) => {
  return addresses.find((a) => a.id === order.address_id);
});

export const getOrderDate = createSelector([getOrder], (order) => order.date);

export const getFirstOrderDate = createSelector([getOrdersByIndex], (ordersByIndex) => ordersByIndex[0]?.date);

export const getOrderDateIsToday = createSelector([getOrder], (order): boolean =>
  order.date ? isToday(parse(order.date, 'yyyy-MM-dd', 0)) : false,
);

export const getOrderDeliverBefore = createSelector([getOrder], (order) => order.deliver_before);

export const getOrderDeliverAfter = createSelector([getOrder], (order) => order.deliver_after);

export const getOrderDeliverMethod = createSelector([getOrder], (order) => order.delivery_method);

export const getOrderAddressId = createSelector([getOrder], (order) => order.address_id);

export const getOrderIncludeSharpsContainer = createSelector([getOrder], (order) => order.include_sharps_container);

export const getOrderFacilityId = createSelector([getOrderAddress], (address) => {
  return address?.dispensing_facility_id;
});

export const getSaveOrderLoading = createSelector([getLoading], ({ saveOrderLoading }) => saveOrderLoading);

export const getIsOrderPickupMethod = createSelector([getOrder], (order) => order.delivery_method === 'pickup');

export const getIsOrderPayAtPickup = createSelector([getOrder], (order) => order.pay_at_pickup);

export const getOrderPickupAddress = createSelector([getOrder], (order) => order.pickup_address);

export const getOrderPickupReadyTime = createSelector([getOrder], (order) => order.pickup_ready_time);
